import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ManageActivitiesModel } from '../../manage-activities/models/manage-activities.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { CellPropertyModel } from '../../company-management/models/cell-property.model';
import { DataListOptionsModel } from '../../company-management/models/data-list-options.model';
import { GeneralResponse } from '../../shared/models/general-response.model';
import { IdName } from '../../shared/models/id-name.model';
import { ActivityProductForms } from '../models/activity-product-forms.model';


@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {
  
    public onHeaderSelect: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
    public recheckValidation: Subject<number> = new Subject();
    public resetPropertyOfDependentCell: Subject<{ rowIndex: number, colKeyCellPropertyMap: Map<string, CellPropertyModel> }> = new Subject();

    constructor(private http: HttpClient) { }

    deleteActivities(activityId: number, moduleType, projectId: number) {
        return this.http.delete<any[]>(
            environment.baseUrl + '/transactions/api/activities/deleteActivityData?&projectId=' + projectId + '&moduleType=' + moduleType + '&activityId=' + activityId);
    }

    getAllActivities(projectId: number) {
        return this.http.get<ManageActivitiesModel[]>(
            environment.baseUrl + '/transactions/api/activities/manageActivities/?projectId=' + projectId);
    }
    getActivityProductFormDetails(projectId: number) {
        return this.http.get<GeneralResponse<ActivityProductForms[]>>(
            environment.baseUrl + '/setting/api/customType/activity-product-form-details?projectId=' + projectId);
    }

    uploadActivity(bulkData) {
        return this.http.post(
            environment.baseUrl + '/upload/api/bulk-upload/activities/add', bulkData);
    }

    uploadProductForm(bulkData) {
        return this.http.post(
            environment.baseUrl + '/upload/api/bulk-upload/activities/product-forms/add', bulkData);
    }

    getMappedDataListOptions(projectId: number, moduleType: number) {
        return this.http.get<DataListOptionsModel[]>(environment.baseUrl + '/transactions/api/customType/getMappedDataListOptions?projectId=' + projectId + '&moduleType=' + moduleType);
    }

    getEntityIdentifiers(projectId: number, moduleType: number) {
        return this.http.get<GeneralResponse<IdName[]>>(environment.baseUrl + '/transactions/api/common/get-identifiers?projectId=' + projectId + '&moduleType=' + moduleType);
    }
}