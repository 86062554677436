<div class="example-preview">
    <form  [formGroup]="settingActivityForm" autocomplete="off" >           
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">Activity Name
                    <span class="mandatory-marker">*</span>
                </label>
            </div>

            <div class="col-md-8">
                <mat-form-field appearance="standard" class="input-100" >
                    <input matInput #inputActivity  maxlength="500" formControlName="activityName" [(ngModel)]="activityName" ktAutoTrimSpaces>
                
                    <mat-hint class="color-a" align="end">{{inputActivity.value?.length || 0}}/500</mat-hint>
                    <mat-error *ngIf="isControlHasError('activityName', 'required')">Name cannot be blank/Maximum 50 characters</mat-error>
                        <mat-icon  matSuffix class="color-b">text_fields</mat-icon>
                </mat-form-field> 
            </div>    
        </div>
        <div class="height-a"></div>


        <!-- Associate activity -->
        <div class="form-group row mt-6">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-5"> Associate this Activity with </label>
           </div>
           <div class="col-md-8">

                <mat-checkbox [checked]="true" [class.mat-checkbox-disabled]="false"  disabled>
                    Activities
                </mat-checkbox>

                &nbsp;&nbsp;&nbsp;&nbsp;

                <mat-checkbox #checkbox1  formControlName="allowForCompany" [(ngModel)]="allowCompany" (change)="enableOrDisableGeofence()" [checked]="false" >
                    {{allowCompanyName}}
                </mat-checkbox>

                &nbsp;&nbsp;&nbsp;&nbsp;
              <span *ngIf="opportunityEnabled" >
                <mat-checkbox #checkbox2 formControlName="allowForOpportunity" [(ngModel)]="allowOpportunity"  (change)="openDialog()" [checked]="false" >
                    {{allowOpportunityName}}
                </mat-checkbox>  
              </span> 
              &nbsp;&nbsp;&nbsp;&nbsp;
              <mat-checkbox #checkbox3 formControlName="allowForDialer" [(ngModel)]="allowDialer" [checked]="false" >
                {{allowDialerName}}
            </mat-checkbox>  

                <br>
                <mat-hint class="form-hint" align="start"  class="color-c">Tick the options from where this activity can be performed</mat-hint> 
           </div>  
        </div> 


        <div class="height-a"></div>
        <div *ngIf="opportunityEnabled" class="form-group row ">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-5">Enforce Live Location Capture while Adding from App</label>
           </div>
           <div class="col-md-8">
                <mat-checkbox  formControlName="captureLiveLocation" [(ngModel)]="captureLiveLocation" checked>
                    Yes
                </mat-checkbox>
                <br>
                <mat-hint class="form-hint" align="start"  class="color-c">Tick this if you want to enable real time location during addition</mat-hint> 
           </div>  
        </div> 

        <div class="height-b"></div>
        
        <div class="form-group row">
            <div class="col-md-4">
                <label col-form-label form-padding-top-35>Geofence</label>
            </div>
            <div class="col-md-8">
                <mat-checkbox #checkbox3  formControlName="geofence" [(ngModel)]="geofence" (change)="addGeofenceField(geofence)"> Yes
                </mat-checkbox>
                <br>
                <mat-hint class="form-error color-c">Select to activate geofence</mat-hint>
            </div>    
        </div>

        <div *ngIf="geofence==true" class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">Geofence Distance (in mtr)
                    <span class="mandatory-marker">*</span>
                </label>
            </div>
            <div class="col-md-8">
                <mat-form-field appearance="standard" class="input-100" >
                    <input matInput #inputGeoDist  maxlength="5" formControlName="geofenceDistance" ktStrictNumberOnlyDirective [(ngModel)]="geofenceDistance" >
                    <mat-hint class="pad-right">Only numeric values are allowed.</mat-hint>
                    <mat-hint class="color-c" align="end">{{inputGeoDist.value?.length || 0}}/5</mat-hint>
                    <mat-error *ngIf="isControlHasError('geofenceDistance', 'required')">Geofence Distance cannot be blank.</mat-error>
                        <mat-icon  matSuffix class="color-b">format_list_numbered</mat-icon>
                </mat-form-field>                    
            </div>
        </div>  

        <div styles="height: 10px;"></div>
                <div class="form-group row mt-6">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Key
                            <span class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input  maxlength="5" formControlName="serialKey" ktAutoTrimSpaces>    
                            <mat-hint align="start" style="color: rgba(0,0,0,.54);">
                            </mat-hint>
                            <mat-hint align="end" style="color: rgba(0,0,0,.54)"> {{input.value?.length || 0}}/5</mat-hint>
                            <mat-error *ngIf="isControlHasError('serialKey', 'required')">Serial No. Key cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError('serialKey', 'maxlength')"><strong>Maximum 5 characters</strong></mat-error>
                            <mat-icon *ngIf="!anyTransactionPerformed" matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                            <mat-icon *ngIf="anyTransactionPerformed" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>
    
        <!-- Show Objective Checkbox -->
        <div class="form-group row mt-6">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">Show Objective</label>
            </div>
            <div class="col-md-8">
                <mat-checkbox formControlName="showObjective">
                    Yes
                </mat-checkbox>
                <br>
                <mat-hint align="start" class="form-hint color-c">Tick this checkbox if you want to show objective of the activity</mat-hint>
            </div>    
        </div>
        
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">Status
                    <span class="mandatory-marker">*</span>
                </label>
            </div>
            <div class="col-md-8">
                <mat-form-field class="mat-form-field-fluid" >
                    <mat-select formControlName="status" >
                        <mat-option value="1">Active</mat-option>
                        <mat-option value="0">Inactive</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        
        <div class="row" ktMargin [marginBottom]="'10px'"></div>
       
        <div class="form-group row custom-toggler">
            <div class="ml-4">
                <kt-switch custom="custom" size="small" formControlName="mySwitch" (change)="changed($event)"></kt-switch>
            </div>
            <div class="col-md-11 pt-1 h6">Advanced Settings</div>
        </div>
<!-- Advance Search Start -->
<div *ngIf="advanceSetting">
    <div class="form-group row mt-4">
        <div class="col-md-3">
            <label class="col-form-label form-padding-top-15">Active for
                <span class="mandatory-marker">*</span>
            </label>
        </div>
        <div class="col-md-9">
            <mat-form-field class="mat-form-field-fluid" >
                <mat-select 
                formControlName="activeFor"
                (selectionChange)="onActiveFor($event.value)" >
                    <mat-option value="2">Users</mat-option>
                    <mat-option value="1">Roles</mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="isControlHasError('activeFor', 'required')">Select value in this field</mat-error> -->
                <mat-hint class="form-hint" align="start"  class="color-c">Select to activate this activity for selected users, blank defaults to no restriction </mat-hint> 
            </mat-form-field> 
            <span class="text-danger position-absolute small" *ngIf="formSubmitted && !activeFor">Active for cannot be blank.</span>
        </div>
    </div>

    <div class="form-group row mt-4" *ngIf="activeFor == 'users'">
        <div class="col-md-3">
            <label class="col-form-label form-padding-top-15">Select Users
                <span class="mandatory-marker">*</span>
            </label>
        </div>
        <div class="col-md-9">
            <mat-form-field class="mat-form-field-fluid" >
                <mat-select  #select1
                formControlName="selectUser"
                [(ngModel)]="selectedIds"
                [multiple]="true" (selectionChange)="onUserChange($event.value)" >
                <div class="select-all">
                    <mat-checkbox [(ngModel)]="allSelected"
                            [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection(select1)">Select All
                    </mat-checkbox>
                  </div>
                    <mat-option (click)="optionClick(select1)"*ngFor="let user of userList;" value="{{user.userId}}">{{user.firstName}} {{user.lastName}}</mat-option>
                </mat-select>
            </mat-form-field> 
            <span class="text-danger position-absolute margin-minus small" *ngIf="formSubmitted && (!selectedUser || !selectedUser.length)">Users cannot be blank.</span>
        </div>
    </div>
    <div class="form-group row mt-4" *ngIf="activeFor == 'roles'">
        <div class="col-md-3">
            <label class="col-form-label form-padding-top-15">Select Roles
                <span class="mandatory-marker">*</span>
            </label>
        </div>
        <div class="col-md-9">
            <mat-form-field class="mat-form-field-fluid" >
                <mat-select #select2
                formControlName="selectRole"
                [(ngModel)]="selectedIds"
                [multiple]="true" (selectionChange)="onRoleChange($event.value)" >
                <div class="select-all">
                    <mat-checkbox [(ngModel)]="allSelected"
                            [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection(select2)">Select All
                    </mat-checkbox>
                  </div>
                    <mat-option (click)="optionClick(select2)" *ngFor="let role of roleList;" value="{{role.roleId}}">{{role.roleName}}</mat-option>
                </mat-select>
            </mat-form-field> 
            <span class="text-danger position-absolute margin-minus small" *ngIf="formSubmitted && (!selectedRoles || !selectedRoles.length)">Roles cannot be blank.</span>
        </div>
    </div>

    <div class="form-group row mt-8">
        <div class="col-md-3">
            <label class="col-form-label form-padding-top-15">Activity Period</label>
        </div>
        <div class="col-md-4">
            <span class="text-danger position-absolute small" *ngIf="invalidStartDate">Start date must be in future or current date</span>
            <mat-form-field class="example-full-width">
                <input matInput readonly formControlName="startDate" 
                    (dateInput)="strtDate($event.target.value)" [min]="todayDate"
                    [matDatepicker]="picker" >
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint class="form-hint" align="start">Select start-date for this activity, blank defaults to no limit  </mat-hint> 
              </mat-form-field>
        </div>
        <div class="col-md-4">
            <div class="row">
                <!-- <mat-form-field class="mat-form-field-fluid" class="width-a">
                    <mat-select placeholder="Hours" 
                    formControlName="startHours"
                    (selectionChange)="timeChange($event.value, 'startHH')">
                        <mat-option  *ngFor="let hh of hours;" [value]="hh">{{hh}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <input class="form-control" 
                    style="width: 80px;"
                    type="number" 
                    formControlName="startHours"
                    (input)="timeChange($event.target.value, 'startHH')">
                &nbsp;&nbsp;&nbsp; <span class="margintop-a text-primary"> : </span>&nbsp;&nbsp;&nbsp;
                <!-- <mat-form-field class="mat-form-field-fluid" class="width-a">
                    <mat-select placeholder="Minutes" 
                    formControlName="startMin"
                    (selectionChange)="timeChange($event.value, 'startMM')">
                        <mat-option  *ngFor="let mm of Minutes;" [value]="mm">{{mm}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <input class="form-control" 
                    style="width: 80px;"
                    type="number" 
                    formControlName="startMin"
                    (input)="timeChange($event.target.value, 'startMM')">
               
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button (click)="timeChange('AM', 'startAMPM')" [ngClass]="{'text-primary border-primary' : startAMPM == 'AM'}" class="text-dark form-control width-a" [disabled]="disableButton">AM</button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button (click)="timeChange('PM', 'startAMPM')" [ngClass]="{'text-primary border-primary' : startAMPM == 'PM'}" class="text-dark form-control width-a" [disabled]="disableButton">PM</button>
            </div>
            <span class="endTime">Start time</span>&nbsp;&nbsp;&nbsp;
            <small class="text-danger" *ngIf="!isStartDatePresent">Please select the start date</small>&nbsp;
            <small class="text-danger" *ngIf="startMM.length>0 && startHH.length==0">Hours cannot be empty </small>&nbsp;
            <small class="text-danger" *ngIf="startHH.length>0 && startMM.length==0">Minutes cannot be empty </small>&nbsp;
            <small class="text-danger" *ngIf="startHH > 12">Hours should be less or equal to 12</small>
            <small class="text-danger" *ngIf="startHH < 0">Hours should be grater then 0</small>&nbsp;&nbsp;&nbsp;
            <small class="text-danger" *ngIf="startMM > 60">Minutes should be less or equal to 60</small>
            <small class="text-danger" *ngIf="startMM < 0">Minutes should be grater then 0</small>
        </div>
    </div>

    <div class="form-group row mt-8">
        <div class="col-md-3">
            <label class="col-form-label form-padding-top-15"></label>
        </div>
        <div class="col-md-4">
            <span class="text-danger position-absolute small" *ngIf="invalidEndDate">End date must be greater than Start date</span>
            <mat-form-field class="example-full-width">
                <input matInput readonly formControlName="endDate"
                (dateInput)="endDateInput()" [min]="lastEndDate"
                 [matDatepicker]="picker1" >
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-hint class="form-hint" align="start">Select end-date for this activity, blank defaults to no limit  </mat-hint> 
              </mat-form-field>
        </div>
        <div class="col-md-4">
            <div class="row">
                <!-- <mat-form-field class="mat-form-field-fluid" class="width-a">
                    <mat-select placeholder="Hours"
                    formControlName="endHours"
                    (selectionChange)="timeChange($event.value, 'endHH')">
                        <mat-option  *ngFor="let hh of hours;" [value]="hh">{{hh}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <input class="form-control" 
                    style="width: 80px;"
                    type="number" 
                    formControlName="endHours"
                    (input)="timeChange($event.target.value, 'endHH')">

                &nbsp;&nbsp;&nbsp; <span class="text-primary margintop-a"> : </span>&nbsp;&nbsp;&nbsp;
                <!-- <mat-form-field class="mat-form-field-fluid" class="width-a">
                    <mat-select placeholder="Minutes"
                    formControlName="endMin"
                    (selectionChange)="timeChange($event.value, 'endMM')">
                        <mat-option  *ngFor="let mm of Minutes;" [value]="mm">{{mm}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <input class="form-control" 
                    style="width: 80px;"
                    type="number" 
                    formControlName="endMin"
                    (input)="timeChange($event.target.value, 'endMM')">
               
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button (click)="timeChange('AM', 'endAMPM')" [ngClass]="{'text-primary border-primary' : endAMPM == 'AM'}" class="text-dark form-control width-a" >AM</button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button (click)="timeChange('PM', 'endAMPM')" [ngClass]="{'text-primary border-primary' : endAMPM == 'PM'}" class="text-dark form-control width-a" >PM</button>
            </div>
            <span class="endTime">End time</span>&nbsp;&nbsp;&nbsp;
            <small class="text-danger" *ngIf="!isEndDatePresent ">Please select the end date</small>&nbsp;
            <small class="text-danger" *ngIf="endMM.length>0 && endHH.length==0">Hours cannot be empty </small>&nbsp;
            <small class="text-danger" *ngIf="endHH.length>0 && endMM.length==0">Minutes cannot be empty </small>&nbsp;
            <small class="text-danger" *ngIf="endHH > 12">Hours should be less or equal to 12</small>
            <small class="text-danger" *ngIf="endHH < 0">Hours should be grater then 0</small>&nbsp;&nbsp;&nbsp;
            <small class="text-danger" *ngIf="endMM > 60">Minutes should be less or equal to 60</small>
            <small class="text-danger" *ngIf="endMM < 0">Minutes should be grater then 0</small>
        </div>
    </div>

    <div class="form-group row mt-4">
        <div class="col-md-3">
            <label col-form-label form-padding-top-35>Apply Additional Filters</label>
        </div>
        <div class="col-md-9">
            <mat-checkbox #checkbox4 (change)="aditionalChange($event)" formControlName="additionalFilter"> Yes
            </mat-checkbox>
            <br>
            <mat-hint class="form-error color-c">Tick this if you want this activity to be performed at specific customers or opportunities </mat-hint>
        </div>
    </div>

<!-- <div *ngIf="additionalFilterDisplay">
    <div class="form-group row mt-4" *ngFor="let value of entityValues; let i = index">
        <div class="col-md-3">
            <label *ngIf="i == 0" col-form-label form-padding-top-35>Select Values</label>
        </div>
        <div class="col-md-2">
            <span>Entity</span>
            <mat-form-field class="mat-form-field-fluid" >
                <mat-select
                placeholder="Select entity"
                (selectionChange)="onEntityChange($event.value, i)">
                    <mat-option *ngIf="allowCompany" value="2">Customers</mat-option>
                    <mat-option *ngIf="allowOpportunity" value="3">Opportunity</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <span>Entity Field</span>
            <mat-form-field class="mat-form-field-fluid">
                <mat-select 
                [placeholder]="entityValues[i].entityId == 3 ? 'Selected opportunity type' : 'Selected customer type'"
                 (selectionChange)="onEntityFieldChange($event.value, i)">
                    <mat-option *ngFor="let field of fieldList[i];" [value]="field">{{field.combinedAttributeName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <span>Value</span>
            <mat-form-field class="mat-form-field-fluid" >
                <mat-select [multiple]="true"
                 placeholder="Selected Values" 
                 (selectionChange)="onEntityFieldValue($event.value, i)">
                    <mat-option [disabled]="true" *ngIf="fieldValuesList && fieldValuesList[i] && !fieldValuesList[i].length">No record found</mat-option>
                    <mat-option *ngFor="let value of fieldValuesList[i];" [value]="value.id">{{value.lookupValue}}</mat-option>
                </mat-select>
            </mat-form-field> 
        </div> 
        <div class="col-md-1">
            <div *ngIf="entityValues.length > 1" class="minus-btn" (click)="removeValue(value, i)">
                -
            </div>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-2">
            <div class="pill-btn row ml-0">
                <div class="and" id="conditionalAnd{{i}}"  (click)="conditionalOper('AND', i)">AND</div>
                <div class="or" id="conditionalOr{{i}}"  (click)="conditionalOper('OR', i)">OR</div>
            </div>
        </div>
    </div>
</div> -->
<div *ngIf="additionalFilterDisplay" formArrayName="extraFields">
    <div class="form-group row mt-6" *ngFor="let value of extraFields.controls; let i = index" [formGroupName]="i">
        <div class="col-md-3 mt-7">
            <label *ngIf="i == 0" col-form-label form-padding-top-35>Select Values</label>
        </div>
        <div class="col-md-2">
            <mat-form-field appearance="standard" class="input-100">
                <mat-label>Entity
                <span class="mandatory-marker">*</span>
                </mat-label>
                <span class="text-danger position-absolute small mt-7" *ngIf="invalidEntity">Add minimum one entity</span>
                <mat-select
                formControlName="entityId"
                (selectionChange)="onEntityChange($event.value, i)"
               >
               <mat-option *ngIf="allowCompany" value="2">{{customerLabel}}</mat-option>
               <mat-option *ngIf="allowOpportunity" value="3">{{opportunityLabel}}</mat-option>
                    
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="standard" class="input-100">
                <mat-label>Entity Field</mat-label>
                <mat-select 
                id="entityIds"
                formControlName="entityFieldId"
               
                (selectionChange)="onEntityFieldChange($event, i)"
                 >
                    <mat-option *ngFor="let field of fieldList[i]; let j = index;" [value]="field.id">{{field.combinedAttributeName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field  appearance="standard" class="input-100">
                <mat-label>Value</mat-label>
               <mat-chip-list #chipList>
                   <mat-chip 
                   *ngFor="let item of selectedValues[i]"
                   [selectable]="selectable"
                   [removable]="removable"
                   (removed)="remove(item, i)"
                   [value]="item.value"
                   > {{item.name}}
                <mat-icon matChipRemove (click)="remove(item, i)">cancel</mat-icon>
                </mat-chip>
                <input 
                class="input-margin1"
                [ngClass]="{'input-margin' : selectedValues && selectedValues[i] && selectedValues[i].lenght}"
                formControlName="entityFieldValue"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                 />
               </mat-chip-list>
               <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, i)">
                <mat-option *ngFor="let value of fieldValuesList[i];" [value]="value.id">
                    {{value.lookupValue}}</mat-option>
               </mat-autocomplete>
                <!-- <mat-select [multiple]="true"
                 placeholder="Selected Values" 
                 formControlName="entityFieldValue"
                 [(ngModel)]="selectedFieldsIds[i]"
                 (selectionChange)="onEntityFieldValue($event.value, i)"
                 >
                    <mat-option [disabled]="true" *ngIf="fieldValuesList && fieldValuesList[i] && fieldValuesList[i].length">No record found</mat-option>
                    <mat-option *ngFor="let value of fieldValuesList[i];" [value]="value.id">
                        {{value.lookupValue}}</mat-option>
                </mat-select> -->
            </mat-form-field>
        </div> 
        <div class="col-md-1">
            <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary dlt-btn" (click)="removeExtraField(i)">
                <i class="fa fa-minus"></i>
            </button>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-2">
            <mat-button-toggle-group #group="matButtonToggleGroup" style="height: 36px; border: 0;" formControlName="conditionOperation" *ngIf="i < extraFields.length-1">
                <mat-button-toggle class="t-width-l" *ngIf="i==0||extraFields.value[0].conditionOperation=='AND'"
                style="height: 36px; width: 56px; border: 1px solid #dadada;
                border-right: none;
                border-radius: 8px 0px 0px 8px;
                font-size: 13px;"
                value="AND" (click)="changeConditionOperator('AND')">
                    <span class="and-b"> AND </span>
                </mat-button-toggle>
                <mat-button-toggle class="t-width-r" *ngIf="i==0||extraFields.value[0].conditionOperation=='OR'" 
                style="height: 36px; width: 56px; border: 1px solid #dadada;
                border-left: none;
                border-radius: 0px 8px 8px 0px;
                font-size: 13px;"
                 value="OR" (click)="changeConditionOperator('OR')">
                 <span class="or-b">  OR </span>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <!-- <div class="pill-btn row ml-0">
                <div class="and" id="conditionalAnd{{i}}"  (click)="conditionalOper('AND', i)">AND</div>
                <div class="or" id="conditionalOr{{i}}"  (click)="conditionalOper('OR', i)">OR</div>
            </div> -->
        </div>
    </div>
</div>
    <div class="form-group row mt-4" *ngIf="additionalFilterDisplay">
        <div class="col-md-3"></div>
        <div class="col-md-2"></div> 
        <div class="col-md-3"></div> 
        <div class="col-md-3"></div> 
        <div class="col-md-1">
            <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3" (click)="addExtraField()">
                <i class="fas fa-plus"></i>
            </button>
        </div>
    </div>

    <div class="form-group row mt-4">
        <div class="col-md-3">
            <label class="col-form-label form-padding-top-15">Allow users to assign this activity to other users 
                <span class="mandatory-marker">*</span>
            </label>
        </div>
        <div class="col-md-9">
            <mat-form-field class="mat-form-field-fluid" >
                <mat-select  formControlName="allowUserToAssignOther">
                    <mat-option value="0" selected>None</mat-option>
                    <mat-option value="1">Any user</mat-option>
                    <mat-option value="2">Up the hierarchy</mat-option>
                    <mat-option value="3">Down the hierarchy</mat-option>
                </mat-select>
            <mat-error class="small" *ngIf="isControlHasError('allowUserToAssignOther', 'required')">Allow users to assign this activity to other users cannot be blank.</mat-error>
            </mat-form-field>
        </div>
    </div>

</div>
<!-- Advance Search End -->
<div class="row" ktMargin [marginBottom]="'20px'"></div>

<div class="modal-footer pr-0">
    <button type="button" class="btn btn-secondary" (click)="ResetForm()">Cancel</button> 
    <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>
</div>

</form> 
</div>


<ngx-spinner class="spn-cls"  bdColor="rgba(0, 0, 0, 0.5)" size="default" color="#fff" type="ball-spin-clockwise" name="editActivity">  
    <p class="color-d">Loading... </p>  
</ngx-spinner> 
