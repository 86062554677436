import { Component, OnInit, ViewEncapsulation, Input, ɵConsole } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import { FormControl } from '@angular/forms';
import { StdLookupAttribute } from 'src/app/views/pages/contact-setting/models/std-lookup-attribute.model';
import { AttributeType } from 'src/app/views/pages/contact-setting/models/attribute-type.model';
import { Contact } from 'src/app/views/pages/contact-setting/models/contact.model';
import { DateAttributes } from 'src/app/views/pages/contact-setting/models/date-attributes.model';
import { LooupValues } from 'src/app/views/pages/contact-setting/models/looup-values.model';
import { ContactListService } from 'src/app/views/pages/contact-setting/services/contact-list.service';
import { CustomService } from 'src/app/views/pages/contact-setting/services/custom.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Company } from '../../company-configuration/_subs/models/company.model';
import { CustomAttibute } from '../../contact-setting/models/custom-attibute.model';
import { FieldService } from '../services/field.service';
import { ListType } from '../../contact-setting/models/list-type.model';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from '../services/event-emitter.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { environment } from 'src/environments/environment';
import { ReferenceDocumentService } from '../services/reference-document.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductFormService } from '../../product-form/service/product-form.service';
import { customAttributesModel } from '../../company-management/models/customAttributes.model';
import { ManageActivitiesService } from '../../manage-activities/services/manage-activities.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { RelationService } from '../../company-configuration/_subs/services/relation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SystemAttributeEnum } from '../constant/SystemAttributeEnum.constants';
import { ProductQuantityTransferModel } from '../add-field/models/quantity-transfer.model';

@Component({
  selector: 'kt-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})
export class EditFieldComponent implements OnInit {
  allSelected=false;
  attribId: number;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes: String = "";
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  lookupVal: LooupValues[];
  lookupValPrev: LooupValues[];
  modalCloseResult = false;
  private unsubscribe: Subject<any>;
  private returnUrl: any;
  loading = false;
  mandatoryContact: boolean;
  allowBrowseVal: boolean;
  uniqueVal: boolean;
  decimalFlag: boolean;
  addValidationFieldFlag: boolean;
  enableRegexField: boolean;
  addValidationField:boolean;
  otpValidationCheck:boolean;
  mandatoryContactFlag: number;
  allowBrowsingFlag: number;
  otpValidationFlag:number;
  uniqueFlag: number;
  statusFlag: number | string;

  stdLookupList: StdLookupAttribute[];
  attributeType: AttributeType;
  characterType: ListType;
  primaryContact: ListType;
  stage: ListType;
  numericValidationType: ListType;
  resultSet: any;
  displayDateValueDiv: boolean;
  displayMultiSelectDiv: boolean;
  displaySingleSelectDiv: boolean;
  displaySingleSelectDivTable: boolean;
  displaySingleContactDivTable: boolean;
  allCompanyTypeList: Company[];
  dateAttributes: DateAttributes;
  standardTypeValue: StdLookupAttribute;
  companyTypes: Company[];
  companyTyp: Company;
  companyTypesId: string;
  customAttributeId: number;
  customAttributeName: string;
  attributeName: string;
  instructions: string;
  selectedattributeType: number;
  attributeTypeVal: string;
  statusVal: string;

  editCustomVal: CustomAttibute;
  row: any;
  editing: boolean;
  dateValueType;
  futureDateValue: number | string;
  previousDateValue: number | string;
  flag_Value: boolean;
  browseVal: boolean;
  unique_Value: boolean;
  allCompanyTypList: Company[];
  disabled:boolean;
  duplicate:boolean;
  attributeTypelist:AttributeType[];
  standardTypelist:StdLookupAttribute[];
  characterTypeList:ListType[];
  numericValidationTypeList:ListType[];
  stageTypeList:ListType[];
  entityTypeList:ListType[];
  allEntityTypeList:ListType[];
  entityfieldList:CustomAttibute[];
  primaryContactTypeList:ListType[];
  isDisplayText:boolean;
  isDisplayImage:boolean;
  isDisplayNumeric:boolean;
  isDisplayEmail:boolean;
  displayRefDoc:boolean;
  moduleType:string | number;
  singleSelectOption:boolean;
  multiSelectOption:boolean;
  disableMandatoryCheck:boolean;
  disableUniqueValCheck:boolean;
  disableAttributeName: boolean;
  disableattributeSingleName: boolean;
  displayFields: boolean;
  displayLinkedFields: boolean;
  entityValue: string;
  linkedAttributeId: string;
  isDisplaySelection: boolean;
  isDisplaySelectionLock: boolean;
  selectionTypeList: AttributeType[];
  duplicateOptions: boolean;
  selectionType:number;
  isDocumentUpload:boolean;
  documentTypes=[];
  isVideoUpload:boolean;

  uploadedFiles = [];
  fileControlValue = [];
  uploadedFileIdObj = [];
  uploadedImageCount:number = 0;
  fileUploadQueue:number=0;
  wrongFormat: boolean = false;
  uploadError: boolean = false;
  uploadErrorFileName: string;
  calculationFormula:string = null;
  normalText: boolean;
  headerText: boolean;
  pageSeperator: boolean;
  selectForm:boolean;
  isOptionsAlphabetically:boolean;
  showOptionSelected: boolean = false;
  showOptionPreselectedFlag: number;
  alphabeticallyFlag:number;
  flag_alphabeticOptions:boolean;

  isEntityField:boolean = false;
  showLogicalField: boolean = false;

  optionsForQuantityTransfer = [{id: 1, name: 'Transfer From'}, {id: 2, name: 'Transfer To'}];
  entityOptionsForQuantityTransfer = [{id: 2, name: 'Customer'}, {id: 5, name: 'User'}];
  valuesForQuantityTransfer: {id: number, name: string}[] = [];
  entityFieldListForQuantityTransfer: CustomAttibute[];
  isEnableForCompanies: number;
  selectCompanyField: string;
  productFormFlag: boolean = false;
  quantityTransferConfigData : {
    isAllowQuantityTransfer: number,
    productQuantityTransferDetail: ProductQuantityTransferModel
  } = null;

  dependentcheckBox=false;
  showDependentFieldDD=false;
  fieldTypeChanged: boolean;
  selctedEntityFieldLV: LooupValues[] = [];
  showEntityFieldLV=false;
  showDependentFieldCB=false;
  dependentFieldList:customAttributesModel[]=[];
  showParentValueDD=false;
  parentLookupValues:LooupValues[] = [];
  selectedEntityField:CustomAttibute;
  isDisplayLinkedField:boolean;
  isDisplayLinkedDataListField: boolean;
  selectDependentFields:any;
  selectValues:any = [];
  selectParentvalues = [];
  flag_dependentcheckBox:boolean;
  documentTypeAllSelected:boolean =false;
  logicalFieldName: string = "";
  entityName:string;
  attributeIdentifierName:string;
  otp_validation: boolean =false;
  numericDecimalFieldPattern: string = "-?\\d*(\\.\\d{0,2})?$";
  anyTransactionPerformed:boolean;
 
  public attributeFilterType: FormControl = new FormControl();
  public standardFilterType: FormControl = new FormControl();
  public attributecharacterType: FormControl = new FormControl();
  public attributenumericValidationType: FormControl = new FormControl();
  public stageFilterType: FormControl = new FormControl();
  public contactFilterType: FormControl = new FormControl();
  public entityValueType: FormControl = new FormControl();
  public entityfieldType: FormControl = new FormControl();
  public selectionTypeValue: FormControl = new FormControl();
  public selectParentFieldValue: FormControl = new FormControl();
  public selectValueFilter: FormControl = new FormControl();
  public selectDependentFieldFilter: FormControl = new FormControl();
  public addValidation: FormControl = new FormControl();
  public enterRgx: FormControl = new FormControl();
  public rgxErrorMessage: FormControl = new FormControl();
 

  public editFieldForm: FormGroup;
  disableSelect: boolean;

  public transferOptionFilter: FormControl = new FormControl();
  public entityOptionFilter: FormControl = new FormControl();
  public entityFieldOptionFilter: FormControl = new FormControl();
  public valueFilter: FormControl = new FormControl();
  public previousFormFilter: FormControl = new FormControl();
  
  protected _onDestroy = new Subject<void>();
  myFormValueChanges$;
  myFormValue$;
  productFormList: any;
  isFieldTypeDataList: boolean = false;
  showAllDataListValues: number = 0;
  optionsForActivity = [];
  isPreviousFormcheckboxSelected = false;
  isProductFormSelected: boolean = false;
  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public _field: FieldService,
    private _ps:ProductFormService,
    public contactService: ContactListService,
    public customService: CustomService,
    private eventEmitterService: EventEmitterService,
    public refDocService: ReferenceDocumentService,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog, 
    public snackBar: MatSnackBar,
    private _rs:RelationService,
    private _activityService: ManageActivitiesService) {
    this.unsubscribe = new Subject();
    
  }

  ngOnInit(): void {

    if(this.editCustomVal.linkedAttributeModuleId != 6){
      this.setLookupFieldValue(this.editCustomVal.id);
    }
   else{
    this.getLookupValuesAndDataListDetails(this.editCustomVal.id);
   }

    if((this.editCustomVal.linkedAttributeId != null) && (this.editCustomVal.linkedAttributeId != 0) || (this.editCustomVal.linkedAttributeModuleId == 6)){
      this.isDisplayLinkedField = true;
      this.isEntityField = true;
    } else if(this.editCustomVal.parentFormId != null){
      this.isDisplayLinkedField = true;
      this.isEntityField = false;
      this.isDisplaySelection = true;
    }else {
      this.isDisplayLinkedField = false;
      this.isEntityField = false;
      this.isDisplaySelection = false;
    }
    
    if ((this.editCustomVal.moduleType == '3' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_STATUS_FIELD_ID) ||
      (this.editCustomVal.moduleType == '1' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.CONTACT_TYPE_FIELD_ID)) {
      this.initeditCustomFieldOptionsForm();
    } else {
      this.initeditCustomFieldForm();
    }

    this.editFieldForm.reset();
    this.moduleType? this.getAttributeType(this.moduleType): null;
    this.getcharacterTypeList();
    this.getnumericValidationTypeList();
    this.getstageTypeList();
    this.getprimaryContactTypeList();
    this.getentityTypeList();
    this.editFieldForm.reset();
    this.displayDateValueDiv=false;
    this.displayMultiSelectDiv=false;
    this.displaySingleSelectDiv=false;
    this.isDocumentUpload=false;
    this.displaySingleSelectDivTable=false;
    this.displaySingleContactDivTable=false;
    this.dateAttributes=new DateAttributes();
    this.dateAttributes.stdLookupAttribute= new StdLookupAttribute();
    this.companyTypes= new Array<Company>();
    this.companyTypesId='';
    this.standardTypeValue= new StdLookupAttribute();
    this.disabled=false;
    this.duplicate=false;
    this.isDisplayText=false;
    this.isDisplayImage=false;
    this.isDisplayNumeric=false;
    this.isDisplayEmail=false;
    this.displayRefDoc=false;
    this.moduleType=this.editCustomVal.moduleType;
    this.disableSelect=false;
    this.disableMandatoryCheck=false;
    this.disableUniqueValCheck=false;
    this.singleSelectOption=true;
    this.multiSelectOption=true;
    this.displaySingleOption();
    this.displayMultipleOption();
    this.mandatoryCheckVal();

    this.disableAttributeName = false;
    this.disableattributeSingleName = false;
    this.isDisplaySelection = false;
    this.isDisplaySelectionLock = false;
    
    if([1, 2, 3].includes(+this.moduleType) || +this.moduleType > 100) {
      this.showLogicalField = true;
    }

    if (this.editCustomVal.moduleType == '5') {
      if (this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.USER_WORK_EMAIL_FIELD_ID) {
        this.disableUniqueValCheck = true;
      }
      if (this.editCustomVal.systemAttribute != null && 
        (this.editCustomVal.systemAttribute.id == SystemAttributeEnum.USER_ROLE_FIELD_ID
        || this.editCustomVal.systemAttribute.id == SystemAttributeEnum.USER_REPORTS_TO_FIELD_ID
        || this.editCustomVal.systemAttribute.id == SystemAttributeEnum.USER_SET_PASSWORD_CHECKBOX_FIELD_ID
        || this.editCustomVal.systemAttribute.id == SystemAttributeEnum.USER_STATUS_FIELD_ID)) {
        this.disableattributeSingleName=true;
        this.editFieldForm.controls['attributeSingleName'].disable();
      }
    }
  
    //setup for activity modules code was here

    this.attributeName = this.editCustomVal.attributeName;
    this.instructions = this.editCustomVal.instructions;
    this.attributeIdentifierName = this.editCustomVal.attributeIdentifierName;

    this.editFieldForm.controls['attributeType'].setValue(this.editCustomVal.attributeType.id);
    this.attributeTypeVal = this.editCustomVal.attributeType.typeName;

    this.attributeType = this.editCustomVal.attributeType;
    this.getCustomDateAttribute(this.editCustomVal.id);
    this.characterType.id = this.editCustomVal.characterType;
    this.numericValidationType.id = this.editCustomVal.numericValidationType;
    this.statusFlag = this.editCustomVal.status;
    if (this.statusFlag == 1) {
      this.statusVal = 'Active'
    } else if (this.statusFlag == 0) {
      this.statusVal = 'Inactive'
    }

    var flag = this.editCustomVal.mandatoryFlag;
    if (flag == 1) {
      this.flag_Value = true;
    } else {
      this.flag_Value = false;
    }

    if (this.editCustomVal.addValidation == 1) {
      this.addValidationFieldFlag = true;
      this.enableRegexField = true;
    } else {
      this.addValidationFieldFlag = false;
      this.enableRegexField = false;
    }

    var flag2 = this.editCustomVal.allowBrowsing;
    if (flag2 != null && flag2 == 1) {
      this.browseVal = true;
    } else {
      this.browseVal = false;
    }

    var uniqueFlag = this.editCustomVal.hasUniqueValues;
    if (uniqueFlag != null && uniqueFlag == 1) {
      this.unique_Value = true;
    } else {
      this.unique_Value = false;
    }

    const decimalFlag = this.editCustomVal.decimalFlag;
    if (decimalFlag != null && decimalFlag === 1) {
      this.decimalFlag = true;
    } else {
      this.decimalFlag = false;
    }

    var otpValidationFlag = this.editCustomVal.otpValidation;
    if (otpValidationFlag != null && otpValidationFlag == 1) {
      this.otp_validation = true;
    } else {
      this.otp_validation = false;
    }

    var alphabeticOptionsFlag = this.editCustomVal.isOptionsAlphabetically;
     if (alphabeticOptionsFlag != null && alphabeticOptionsFlag == 1) {
       this.flag_alphabeticOptions = true;
     } else {
	       this.flag_alphabeticOptions = false;
     }

     var preSelectionValFlag = this.editCustomVal.showOptionPreselected;
     if (preSelectionValFlag != null && preSelectionValFlag == 1) {
      this.showOptionSelected = true;
    } else {
        this.showOptionSelected = false;
    }

    this.editFieldForm.controls['status'].setValue(this.editCustomVal.status.toString());

    if (this.editCustomVal.fieldSpec == 0 || this.editCustomVal.fieldSpec == 5) {
      this.disableSelect = true;
      this.editFieldForm.controls['status'].disable();
    } else {
      this.statusFlag = this.editCustomVal.status;
    }

    if((this.editCustomVal.linkedAttributeId!=null && (this.editCustomVal.linkedAttributeId != 0)) && this.editCustomVal.parentId!=null && this.editCustomVal.parentId>0){
      this.editFieldForm.controls['status'].disable();
    }

    if ((this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.COMPANY_GEO_FIELD_ID && this.moduleType == '2') ||
      (this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.CONTACT_GEO_FIELD_ID && this.moduleType == '1') ||
      (this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_GEO_FIELD_ID && this.moduleType == '3')) {
      this.disableAttributeName = true;
      this.editFieldForm.controls.attributeName.disable();
      this.editFieldForm.controls.attributeName.updateValueAndValidity();
    }

    // initialize stream on units
    //this.myFormValueChanges$ = this.editFieldForm.controls['optionsItems'].valueChanges;
    // subscribe to the stream so listen to changes on units
    // this.myFormValueChanges$.subscribe(skuItems => this.updateTotalUnitPrice(skuItems));
    this.disabled = false;

  }

setLookupFieldValue(attribId: number){
    this._field.getAttribLookup(attribId).subscribe(
      (row) => {   
     if(row['statusCode']== 200){  
          this.editCustomVal.lookupValues = row['responseData'];
          this.lookupValPrev = this.editCustomVal.lookupValues;
        }
          this.change(this.editCustomVal.attributeType.id);
      },
      (error) => {
        console.error('Error fetching lookup values:', error);
      }
    );
  }

  getLookupValuesAndDataListDetails(attribId: number) {
    this._field.getDataListLookupValues(attribId).subscribe(
      (row: any) => {
        if(row['statusCode']==200){
        const responseData = row['responseData']; // Retrieve responseData from the row object
            this.editCustomVal.lookupValues = responseData.lookupValues;
            this.editCustomVal.parentLookupValues = responseData.parentLookupValues;
            this.editCustomVal.parentRelationList = responseData.parentRelationList;
            this.editCustomVal.selectedDependentFields = responseData.selectedDependentFields;
            this.editCustomVal.selectedParentFiledValues = responseData.selectedParentFiledValues;
            this.lookupValPrev = this.editCustomVal.lookupValues;
      }
      this.change(this.editCustomVal.attributeType.id);
    },
      (error) => {
        console.error('Error fetching lookup values:', error);
      }
    );
  }

  getDataListLookupValuesForParent(attribId: number) {
    this._field.getDataListLookupValues(attribId).subscribe(
      (row: any) => { 
        if(row['statusCode']==200){
        const responseData = row['responseData']; // Retrieve responseData from the row object
            this.selectedEntityField.lookupValues = responseData.lookupValues; // update lookupValues directly
            this.selectedEntityField.parentLookupValues = responseData.parentLookupValues;
            this.selectedEntityField.parentRelationList = responseData.parentRelationList;
            this.selectedEntityField.selectedDependentFields = responseData.selectedDependentFields;
            this.selectedEntityField.selectedParentFiledValues = responseData.selectedParentFiledValues;
        this.dataListSetup();
      }
    },
      (error) => {
        console.error('Error fetching lookup values:', error);
      }
    );
  }

  setupForActivityModule(){
    if (Number(this.moduleType) > 100 || Number(this.moduleType) == 4) {
      this.displayFields = true;
      if ((this.editCustomVal.linkedAttributeId == null || (this.editCustomVal.linkedAttributeId == 0)) && this.editCustomVal.systemAttribute != null &&
        this.editCustomVal.systemAttribute.id == SystemAttributeEnum.ACTIVITY_SELECT_ESTIMATE_FIELD_ID) {
        this.editFieldForm.controls['fieldValue'].setValue('2');
        this.entityValue = 'Estimate';
        this.linkedAttributeId = 'Estimate';
        this.getselectionTypeList();
        this.isDisplaySelectionLock = true;
        this.editFieldForm.controls['selectionType'].disable();
      }
      else if ((this.editCustomVal.linkedAttributeId == null || this.editCustomVal.linkedAttributeId == 0) && this.editCustomVal.parentFormId == null && this.editCustomVal.parentDataListId==null) {
        this.editFieldForm.controls['fieldValue'].setValue('1');
      }
      else {
        this.editFieldForm.controls['fieldValue'].setValue('2');
        for (let ent of this.allEntityTypeList) {
          if (ent.id == this.editCustomVal.linkedAttributeModuleId) {
            this.entityValue = ent.value;
          }
        }
        if(this.editCustomVal.parentFormId != null){
          this.entityValue = this.entityTypeList?.find(x=>x.id==200)?.value;
          this.editFieldForm.controls.entityValue.setValue(this.entityValue);
          this.isEntityField = false;
          this.setQuantityTransferControlValues();
          this.changeEntity(200);
          this.setPreviousProductFormData();
        }
        if(this.editCustomVal.parentDataListId != null){
          this.changeEntity(6);
        }
        if(this.editCustomVal.linkedAttributeModuleId){
          this.getCustomFields2(this.editCustomVal.linkedAttributeModuleId);
        }
        this.editFieldForm.controls['linkedAttributeId'].setValue(this.linkedAttributeId);
      }
      
    }
     if(this.editCustomVal.attributeType.id==19){
      this.editFieldForm.controls.entityValue.enable();
      this.editFieldForm.controls.linkedAttributeId.enable();
      this.editFieldForm.controls.entityValue.setValue(this.editCustomVal.linkedAttributeModuleId);
      if(Number(this.moduleType) <= 100){
        this.getCustomFields2(this.editCustomVal.linkedAttributeModuleId);
      }
    }
      
  }
  setPreviousProductFormData() {// to prefill
    this.isPreviousFormcheckboxSelected = this.editCustomVal.allowPreviousForm === 1;
    this.editFieldForm.get('previousFormcheckbox').setValue(this.isPreviousFormcheckboxSelected);
    if (this.isPreviousFormcheckboxSelected) {
      this.getPreviousSubmittedFormDetails();
      this.editFieldForm.get('previousForm').setValue(+this.editCustomVal.previousFormActivity);
    }
  }

  getPreviousSubmittedFormDetails(){
    this.SpinnerService.show('edit-field');
    this._field.getPreviousSubmittedFormDetails(this.selectedProject, +this.moduleType, this.editCustomVal.parentFormId).subscribe(
      res => {
        this.optionsForActivity = res['responseData']
        this.SpinnerService.hide('edit-field');
      }, error => {
        this.SpinnerService.hide('edit-field');
      });

  }

  private setQuantityTransferControlValues() {
    this.SpinnerService.show('edit-field');
    this.customService.getQuantityTransferDetails(this.selectedProject, +this.editCustomVal.moduleType,this.editCustomVal.id).subscribe(res => {
      this.quantityTransferConfigData = res['responseData'];
      if(this.quantityTransferConfigData.isAllowQuantityTransfer == 1) {
        if(this.quantityTransferConfigData.productQuantityTransferDetail.entityTypeId != null || this.quantityTransferConfigData.productQuantityTransferDetail.entityTypeId != undefined) {
          this.getEntityFields(this.quantityTransferConfigData.productQuantityTransferDetail.entityTypeId, true);
        }
        this.editFieldForm.controls['quantityTransferCheckbox'].setValue(true);
        this.editFieldForm.controls['customerTransfer'].setValue(this.quantityTransferConfigData.productQuantityTransferDetail.selectCompanyOption);
        this.editFieldForm.controls['entityTransfer'].setValue(this.quantityTransferConfigData.productQuantityTransferDetail.entityTypeId);
        this.editFieldForm.controls['showValues'].setValue(this.quantityTransferConfigData.productQuantityTransferDetail.showValuesOption?.toString());
      } else {
        this.editFieldForm.controls['quantityTransferCheckbox'].setValue(false);
      }
      this.SpinnerService.hide('edit-field');
    }, error => {
      this.SpinnerService.hide('edit-field');
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getControls(), event.previousIndex, event.currentIndex);
  }

  mandatoryCheckVal(){
    if((this.editCustomVal.moduleType=='2' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='1' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='3' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='4' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='5' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='101' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='102' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='104' && this.editCustomVal.fieldSpec==0) ||
         (this.editCustomVal.moduleType=='105' && this.editCustomVal.fieldSpec==0)){
      this.disableMandatoryCheck=true;
    }

    if ((Number(this.editCustomVal.moduleType) > 100 && this.editCustomVal.fieldSpec == 0)) {
      this.disableMandatoryCheck = true;
    }

    if((this.editCustomVal.moduleType=='3' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id== SystemAttributeEnum.OPPORTUNITY_CONTACT_FIELD_ID) ||
        (this.editCustomVal.moduleType=='3' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id== SystemAttributeEnum.OPPORTUNITY_COMPANY_NAME_FIELD_ID) ||
        (this.editCustomVal.moduleType=='1' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id== SystemAttributeEnum.CONTACT_MOBILE_FIELD_ID) ||
        (this.editCustomVal.moduleType=='1' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id== SystemAttributeEnum.CONTACT_EMAIL_FIELD_ID) ||
        (this.editCustomVal.moduleType=='4' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id== SystemAttributeEnum.PRODUCT_PRICE) ||
        (this.editCustomVal.moduleType=='5' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id== SystemAttributeEnum.USER_REPORTS_TO_FIELD_ID) ||
        (this.editCustomVal.moduleType=='293' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id== SystemAttributeEnum.ACTIVITY_ADD_PRODUCTS_FIELD_ID) ||
        (this.editCustomVal.moduleType=='294' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id== SystemAttributeEnum.ACTIVITY_ADD_PRODUCTS_FIELD_ID) ||
        (this.editCustomVal.moduleType>='100' && this.editCustomVal.fieldSpec==0  && this.editCustomVal.systemAttribute!=null && this.editCustomVal.systemAttribute.id== SystemAttributeEnum.ACTIVITY_COMPANY_FIELD_ID))
        {
              this.disableMandatoryCheck=false;
    }

  }

  getcharacterTypeList() {
    this.characterTypeList = new Array<ListType>();
    this.characterType = new ListType();
    this.characterTypeList.push({ id: 1, value: "All" });
    this.characterTypeList.push({ id: 2, value: "Alpha-Numeric" });
    this.characterTypeList.push({ id: 3, value: "Alphabet" });
  }

  getprimaryContactTypeList() {
    this.primaryContactTypeList = new Array<ListType>();
    this.primaryContact = new ListType();
    this.primaryContactTypeList.push({ id: 1, value: "Yes" });
    this.primaryContactTypeList.push({ id: 2, value: "No" });
  }

  getstageTypeList() {
    this.stageTypeList = new Array<ListType>();
    this.stage = new ListType();
    this.stageTypeList.push({ id: 1, value: "In Progress" });
    this.stageTypeList.push({ id: 2, value: "Closed Won" });
    this.stageTypeList.push({ id: 3, value: "Closed Lost" });
  }

  getnumericValidationTypeList() {
    this.numericValidationTypeList = new Array<ListType>();
    this.numericValidationType = new ListType();
    this.numericValidationTypeList.push({ id: 1, value: "Digits" });
    this.numericValidationTypeList.push({ id: 2, value: "Value" });
  }

  getselectionTypeList() {
    this.getProductList();
    this.selectionTypeList = new Array<AttributeType>();
    this.selectionTypeList.push({ id: 1, typeName: "Single Select Dropdown" });
    this.selectionTypeList.push({ id: 2, typeName: "Multi Select Dropdown" });

  }

  getProductList(){
    this.SpinnerService.show('edit-field');
    this._ps.getProductForm(this.selectedProject).subscribe(
      data=>{

        this.productFormList=data['responseData'].filter(row=>row.isActive==1);       
        this.selectionType=this.editCustomVal.parentFormId;
        this.SpinnerService.hide('edit-field');
        
      },
      err=>{
        //this.errorText=true;
      }  
    )
 
  } 

  getAttributeType(data){
    this.customService.getAtrributeType(data).subscribe(
		(result)=>{
        this.attributeTypelist=result;
		},
		error => {
			console.log(error)
    })
  }

  getStandardLookUpList() {
    var attributeId = this.attributeType.id;
    var attributeName = this.attributeType.typeName;
    this.customService.getstdLookUpList(attributeId, attributeName).subscribe(
      (result) => {
        this.standardTypelist = result;
      },
      error => {
        console.log(error)
      })
  }

  getentityTypeList(){
    this.allEntityTypeList= new Array<ListType>();
    this.allEntityTypeList.push({ id: 4, value:"Product"});
    this._activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      result=>{
      result['responseData'].forEach(
        row => {
          if(row.moduleId==2){
            this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
          } 
          if(row.moduleId==3 && row.opportunityEnabled){
            this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
          }
          if(row.moduleId==200){
            this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
          }
          if(row.moduleId==6){
            this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
          }
          if(row.moduleId==1){
            this.allEntityTypeList.push({ id: row.moduleId, value: row.singularName});
          }    
        }
      );
      this.entityTypeList = this.allEntityTypeList;
      this.setupForActivityModule();
      if(this.attributeType!==undefined && this.attributeType!==null){
        if(this.attributeType.id==19){
          this.entityTypeList=this.allEntityTypeList.filter(ent=>[1,2,3].includes(ent.id));
        } 
      }
    },
    err=>{
    }
  )


  }

  getCustomFields(moduleType) {
    this._field.getFieldsInSetting(moduleType, this.selectedProject)
      .subscribe(
        (result) => {
          this.entityfieldList = result['responseData'];
          if(this.editCustomVal.attributeType.id==19){
              if(moduleType ==1 || moduleType == 2 || moduleType == 3){
                this.entityfieldList=this.entityfieldList.filter(ent=>ent.attributeType.id==7);
              }
          }
        },
        error => {
          console.log(error)
        })
  }

  getCustomDateAttribute(attribId: number){
    this._field.getCustomDateAttribute(attribId).subscribe(
      (row) => {   
        this.editCustomVal.dateAttributes = row['responseData'];
        if (null != this.editCustomVal.dateAttributes) {
          if (null != this.editCustomVal.dateAttributes.stdLookupAttribute) {
            this.dateValueType = 'Standard';
            this.editFieldForm.controls['standardTypeValue'].setValue(this.editCustomVal.dateAttributes.stdLookupAttribute.id);
            this.standardTypeValue.id = this.editCustomVal.dateAttributes.stdLookupAttribute.id;
          } else {
            this.dateValueType = 'Custom';
            this.previousDateValue = this.editCustomVal.dateAttributes.previousDateAllowed;
            this.futureDateValue = this.editCustomVal.dateAttributes.futureDateAllowed;
          }
        }
      },
      (error) => {
        console.error('Error fetching dateAttributes values:', error);
      }
    );
  }

  getCustomFields2(moduleType) {
    this._field.getFieldsInSetting(moduleType, this.selectedProject)
      .subscribe(
        (result) => {
          result['responseData'].forEach(
            row => {
              if(moduleType==6){
                if (row.id == this.editCustomVal.parentDataListId) {
                  this.linkedAttributeId = row.attributeName;
                  this.entityfieldList = result['responseData'];
                  this.changeEntityField(this.editCustomVal.parentDataListId);
                  return;
                }
              }
              else{
                if (row.id == this.editCustomVal.linkedAttributeId) {
                  this.linkedAttributeId = row.attributeName;
                  this.entityfieldList = result['responseData'];
                  if(this.editCustomVal.attributeType.id==19){
                    this.editFieldForm.controls['linkedAttributeId'].setValue(row.id);
                  }
                  return;
                }
              }
            }
          )
          this.entityfieldList = result['responseData'];
          if(this.editCustomVal.attributeType.id==19){
              if(moduleType ==1 || moduleType == 2 || moduleType == 3){
                this.entityfieldList=this.entityfieldList.filter(ent=>ent.attributeType.id==7);
              }
          }
         
        },
        error => {
          console.log(error)
        })
  }

  initeditCustomFieldForm() {
    this.SpinnerService.show('edit-field');

    this.editFieldForm = this.fb.group({
      attributeName: ['', Validators.compose([Validators.required, Validators.maxLength(500)])],
      attributeType: [{ value: '', disabled: true }],
      instructions: [],
      dateValueType: [''],
      documentType: [''],
      attributeMultiSelectName:[''],
      attributeSingleName:[],
      refDocument:[''],
      attributeCompanyType:[],
      standardTypeValue:[],
      lookup:[],
      futureDateValue:[],
      previousDateValue:[],
      mandatoryCheck:[],
      unique:[],
      minCharacters:[],
      maxCharacters:[],
      characterType:[],
      maxImagesAllowed:[],
      allowBrowsing:[],
      numericValidationType:[],
      numericMinValue:[null, [Validators.pattern(this.numericDecimalFieldPattern)]],
      numericMaxValue:[null, [Validators.pattern(this.numericDecimalFieldPattern)]],
      minDigits:[],
      maxDigits:[],
      status:[],
      fieldValue:[{value: '', disabled: true}],
      entityValue:[{value: '', disabled: true}],
      linkedAttributeId:[{value: '', disabled: true}],
      selectionType:[],
      selectValues:[],
      selectDependentFields:[],
      selectParentFieldValues:[],
      OptionsItems: this.fb.array([this.getOptions()]),
      logicalFieldName: [{ value: '1', disabled: true }],

      previousForm: [{ value: null, disabled: true }],
      previousFormcheckbox: [{ value: null, disabled: true }],

      quantityTransferCheckbox: [],
      customerTransfer: [],
      entityTransfer: [],
      entityFieldTransfer: [],
      entityFieldValueTransfer: [],
      showValues: [],
      addValidation: [],
      enterRgx: [null, [this._field.regexValidator()]],
      rgxErrorMessage: []
     });
     this.SpinnerService.hide('edit-field');
  }

  initeditCustomFieldOptionsForm() {
    this.SpinnerService.show('edit-field');
    this.editFieldForm = this.fb.group({
      attributeName: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      attributeType: [{ value: '', disabled: true }],
      instructions: [],
      dateValueType: [''],
      documentType: [''],
      refDocument:[''],
      attributeMultiSelectName: [''],
      attributeSingleName: [],
      attributeCompanyType: [],
      standardTypeValue: [],
      lookup: [],
      futureDateValue: [],
      previousDateValue: [],
      mandatoryCheck: [],
      unique: [],
      minCharacters: [],
      maxCharacters: [],
      characterType: [],
      maxImagesAllowed: [],
      allowBrowsing: [],
      numericValidationType: [],
      numericMinValue: [],
      numericMaxValue: [],
      minDigits: [],
      maxDigits: [],
      status: [],
      addValidation: [],
      enterRgx: [],
      rgxErrorMessage: [],
      OptionsItems: this.fb.array([this.getOptions()]),
      logicalFieldName: [{ value: '1', disabled: true }],
    });
    this.SpinnerService.hide('edit-field');
  }

  private getOptions() {
    return this.fb.group({
      id: [],
      OptionVal: [null,RxwebValidators.unique()],
      stageId: []
    });
  }

  addTable() {
    const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
    control.push(this.getOptions());
    setTimeout(() => {
      (document.querySelector('#option'+(control.length-1)) as HTMLElement).focus();
    }, 10);
  }

  deleteRow(i: number) {
    const control = <FormArray>this.editFieldForm.controls['OptionsItems'];

    if ((this.editFieldForm.get('OptionsItems') as FormArray).controls.length > 1) {
      control.removeAt(i);
    }
  }

  addArrayForDivTable() {
    const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
    const itemsArray = [];
    this.editCustomVal.lookupValues.forEach(data => {
      itemsArray.push(
        {
          "id": data.id,
          "OptionVal": data.lookupValue,
          "stageId": data.stageId,
        }
      );
    })
    for (let i = 1; i < itemsArray.length; i++) {
      control.push(this.getOptions());
    }
    
    this.editFieldForm.patchValue({ OptionsItems: itemsArray });
    
  }

  addArray(){
    const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
    const itemsArray = [];
    this.editCustomVal.lookupValues.forEach(data => {
      itemsArray.push(
        {
          "id": data.id,
          "OptionVal": data.lookupValue
        }
      );
    })
    for (let i = 1; i < itemsArray.length; i++) {
      control.push(this.getOptions());
    }
      this.editFieldForm.patchValue({ OptionsItems: itemsArray });
  }

  getEntityFields(moduleType: number, isFirst: boolean) {
    this.SpinnerService.show('edit-field');
    this._field.getFieldsInSetting(moduleType, this.selectedProject).subscribe(result => {
      this.entityFieldListForQuantityTransfer = result['responseData']
        .filter(item => (item.attributeType?.id==1 || item.attributeType?.id==2) && (item.systemAttribute?.id!=16
          && item.systemAttribute?.id!=17 && item.systemAttribute?.id!=59 && item.systemAttribute?.id!=61));
      
      if(isFirst) {
        let data;
        for (const item of this.entityFieldListForQuantityTransfer) {
          if (item.id === this.quantityTransferConfigData.productQuantityTransferDetail.entityFieldId) {
            data = item;
          }
        }
        this.editFieldForm.controls['entityFieldTransfer'].setValue(data);
        this.changeEntityFieldForQuantityTransfer(data, true);
      }
      this.SpinnerService.hide('edit-field');
    }, error => {
      this.SpinnerService.hide('edit-field');
    });
  }

  Save() {
    this.disabled = true;
    if (this.displaySingleSelectDivTable || this.displaySingleContactDivTable || this.displaySingleSelectDiv || this.displayMultiSelectDiv) {
      const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
      var length = this.editFieldForm.controls.OptionsItems.value.length;

      for (var i = 0; i < length; i++) {
        control.at(+i).get('OptionVal').setValidators([Validators.required,RxwebValidators.unique()]);
        control.at(+i).get('OptionVal').updateValueAndValidity();

        if(this.displaySingleSelectDivTable || this.displaySingleContactDivTable){
          control.at(+i).get('stageId').setValidators([Validators.required]);
          control.at(+i).get('stageId').updateValueAndValidity();
        }
      }

      for (var i = 0; i < length; i++) {
        if (control.at(+i).get('OptionVal').invalid) {
          control.at(+i).get('OptionVal').markAllAsTouched();
          this.disabled = false;
          return;
        }

        if (control.at(+i).get('stageId').invalid) {
          control.at(+i).get('stageId').markAllAsTouched();
          this.disabled = false;
          return;
        }

      }

    }
    if (this.attributeType.id == 2) {
      if (this.lookupVal != null && this.lookupVal.length != 0) {
        this.editFieldForm.controls['attributeMultiSelectName'].clearValidators();
        this.editFieldForm.controls['attributeMultiSelectName'].updateValueAndValidity();
      }
    }

    if (this.attributeType.id == 1) {
      if (this.lookupVal != null && this.lookupVal.length != 0) {
        this.editFieldForm.controls['attributeSingleName'].clearValidators();
        this.editFieldForm.controls['attributeSingleName'].updateValueAndValidity();
      }
    }

    const controls = this.editFieldForm.controls;
    if (this.editFieldForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

      const addAttributeData = {
        referenceDocumentUrl: controls.refDocument.value,
        dateValueType: controls.dateValueType.value,
        companyTypes: controls.attributeCompanyType.value,
        documentType: controls.documentType.value? controls.documentType.value.join(): '',
        singleValues: controls.attributeSingleName.value,
        ValuesMutli: controls.attributeMultiSelectName.value,
        futureDateValue: controls.futureDateValue.value,
        previousDateValue: controls.previousDateValue.value,
        instructions: controls.instructions.value==null? '': controls.instructions.value.trim(),
        characterType:controls.characterType.value,
        minCharacters:controls.minCharacters.value,
        maxCharacters:controls.maxCharacters.value,
        allowBrowsing:controls.allowBrowsing.value,
        maxImagesAllowed:controls.maxImagesAllowed.value,
        numericValidationType:controls.numericValidationType.value,
        minDigits:controls.minDigits.value,
        maxDigits:controls.maxDigits.value,
        minNumber:controls.numericMinValue.value,
        maxNumber:controls.numericMaxValue.value,
        linkedAttributeId:this.editCustomVal.attributeType.id==19 ? controls.linkedAttributeId.value:this.editCustomVal.linkedAttributeId,
        parentFormId:controls.selectionType?.value,
        parentDataListId:this.editCustomVal.parentDataListId,
        selectParentvalues: null,
        selectDependentFields: null,
        dependentFilelds: null,
        parentFieldValues: null,
        addDataListDependentFields: this.dependentcheckBox ? 1 : 0,
        showAllDataListValues: null,
        attributeIdentifierName: controls.logicalFieldName.value,
        isAllowQuantityTransfer: controls.quantityTransferCheckbox?.value ? 1 : 0,
        ispreviousFormSelected: controls.previousFormcheckbox?.value ? 1: 0,
        previousFormActivity: null,
        productQuantityTransferDetail: null,
        addValidationFieldFlag: this.addValidationFieldFlag ? 1 : 0,
        regexExpression: controls.enterRgx.value,
        errorMessage: controls.rgxErrorMessage.value
      };
      
      if(addAttributeData.ispreviousFormSelected == 1){
        addAttributeData.previousFormActivity = controls.previousForm.value;
      }
      
      if(addAttributeData.isAllowQuantityTransfer == 1) {
        const commaSeparatedValue = controls.entityFieldValueTransfer.value == null ? null : controls.entityFieldValueTransfer.value.join(', ');
        const quantityTransferFormData: ProductQuantityTransferModel = {
          id: this.quantityTransferConfigData.productQuantityTransferDetail.id,
          activityTypeId: this.quantityTransferConfigData.productQuantityTransferDetail.activityTypeId,
          attributeId: this.quantityTransferConfigData.productQuantityTransferDetail.attributeId,
          isAllowQuantityTransfer: 1,
          projectId: this.selectedProject,
          selectCompanyOption: controls.customerTransfer?.value,
          entityTypeId: controls.entityTransfer?.value,
          entityFieldId: controls.entityFieldTransfer?.value?.id,
          value: commaSeparatedValue,
          showValuesOption: controls.showValues?.value
        };
        addAttributeData.productQuantityTransferDetail = quantityTransferFormData;
      }
      else if(this.productFormFlag && this.quantityTransferConfigData.isAllowQuantityTransfer==1) {
        const quantityTransferFormData: ProductQuantityTransferModel = {
          id: this.quantityTransferConfigData.productQuantityTransferDetail.id,
          activityTypeId: this.quantityTransferConfigData.productQuantityTransferDetail.activityTypeId,
          attributeId: this.quantityTransferConfigData.productQuantityTransferDetail.attributeId,
          isAllowQuantityTransfer: 0,
          projectId: this.selectedProject,
          selectCompanyOption: null,
          entityTypeId: null,
          entityFieldId: null,
          value: null,
          showValuesOption: null
        };
        addAttributeData.productQuantityTransferDetail = quantityTransferFormData;
      }

      if(addAttributeData.numericValidationType == 1){
        var min = addAttributeData.minDigits;
        var max = addAttributeData.maxDigits;
        if(min!="" && max!=""){
          if(min!=null && max!=null){
            if(Number(min) > Number(max)){
              var message='Minimum digits cannot be greater than Maximum digits';
              this.OpenAlertValidation(message);
              this.disabled=false;
              return false;
            }
          }
        }
      }
      if (addAttributeData.numericValidationType == 2) {
        var min = addAttributeData.minNumber;
        var max = addAttributeData.maxNumber;
        if (min != "" && max != "") {
          if (min != null && max != null) {
            if (Number(min) > Number(max)) {
              var message = 'Minimum value cannot be greater than Maximum value';
              this.OpenAlertValidation(message);
              this.disabled = false;
              return false;
            }
          }
        }
      }

    if (this.attributeType.id == 6 || this.attributeType.id == 8) {
      var min = addAttributeData.minCharacters;
      var max = addAttributeData.maxCharacters;
      if (min != "" && max != "") {
        if (min != null && max != null) {
          if (Number(min) > Number(max)) {
            var message = 'Minimum characters cannot be greater than Maximum characters';
            this.OpenAlertValidation(message);
            this.disabled = false;
            return false;
          }
        }
      }
    }

    if (this.disableMandatoryCheck) {
      this.mandatoryContactFlag = this.editCustomVal.mandatoryFlag;
    } else {
      if (this.mandatoryContact == undefined || this.mandatoryContact == null) {
        this.mandatoryContactFlag = this.editCustomVal.mandatoryFlag;
      } else {
        if (this.mandatoryContact == true) {
          this.mandatoryContactFlag = 1;
        } else {
          this.mandatoryContactFlag = 0;
        }
      }
    }

    if (this.allowBrowseVal == undefined || this.allowBrowseVal == null) {
      this.allowBrowsingFlag = this.editCustomVal.allowBrowsing;
    } else {
      if (this.allowBrowseVal == true) {
        this.allowBrowsingFlag = 1;
      } else {
        this.allowBrowsingFlag = 0;
      }
    }

    if (this.uniqueVal == undefined || this.uniqueVal == null) {
      this.uniqueFlag = this.editCustomVal.hasUniqueValues;
    } else {
      if (this.uniqueVal == true) {
        this.uniqueFlag = 1;
      } else {
        this.uniqueFlag = 0;
      }
    }

    if (this.otpValidationCheck == undefined || this.otpValidationCheck == null) {
      this.otpValidationFlag = this.editCustomVal.otpValidation;
    } else {
      if (this.otpValidationCheck == true) {
        this.otpValidationFlag = 1;
      } else {
        this.otpValidationFlag = 0;
      }
    }

    if (this.showOptionSelected == undefined || this.showOptionSelected == null) {
      this.showOptionPreselectedFlag = 0;
    } else {
      if (this.showOptionSelected == true) {
        this.showOptionPreselectedFlag = 1;
      } else {
        this.showOptionPreselectedFlag = 0;
      }
    }

    if (this.isOptionsAlphabetically == undefined || this.isOptionsAlphabetically == null) {
      this.alphabeticallyFlag = 0;
    } else {
      if (this.isOptionsAlphabetically == true) {
        this.alphabeticallyFlag = 1;
      } else {
        this.alphabeticallyFlag = 0;
      }
    }

    this.lookupVal = [];

    if (this.attributeType.id == 3 || this.attributeType.id == 21) {
      if (addAttributeData.dateValueType == 'Standard') {
        this.dateAttributes.stdLookupAttribute.id = this.standardTypeValue.id;
        this.dateAttributes.stdLookupAttribute.value = this.standardTypeValue.value;
        this.dateAttributes.previousDateAllowed = null;
        this.dateAttributes.futureDateAllowed = null;
        if (null != this.editCustomVal.dateAttributes) {
          this.dateAttributes.id = this.editCustomVal.dateAttributes.id;
        }
      } else if (addAttributeData.dateValueType == 'Custom') {
        this.dateAttributes.previousDateAllowed = addAttributeData.previousDateValue;
        this.dateAttributes.futureDateAllowed = addAttributeData.futureDateValue;
        this.dateAttributes.stdLookupAttribute = null;
        if (null != this.editCustomVal.dateAttributes) {
          this.dateAttributes.id = this.editCustomVal.dateAttributes.id;
        }
      } else {
        this.dateAttributes = null;
      }
    }

    if (this.disableSelect) {
      this.statusFlag = this.editCustomVal.status;
    } else {
      this.statusFlag = controls.status.value == true ? 1 : 0
    }

    if (this.disableAttributeName) {
      this.attributeName = this.editCustomVal.attributeName;
    }
    if (this.displaySingleSelectDivTable || this.displaySingleContactDivTable || this.displaySingleSelectDiv || this.displayMultiSelectDiv) {
      const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
      var length = this.editFieldForm.controls.OptionsItems.value.length;
      for (var i = 0; i < length; i++) {
        var optValue = control.at(+i).get('OptionVal').value.trim();
          this.lookupVal.push({
            id: control.at(+i).get("id").value,
            lookupValue: optValue,
            isActive: 1, stageId: control.at(+i).get('stageId').value
          });
    }
  }

  if(this.selectedEntityField != undefined && this.selectedEntityField.moduleType==6){
    if(this.selctedEntityFieldLV.length>0 && this.selectValues?.length>0)
    {
      const prevLookUpVal=this.lookupVal;
      this.lookupVal= this.selctedEntityFieldLV
      .filter(x=>this.selectValues.includes(x.id))
      .map(y => {
        let prevValue = this.lookupValPrev .find(a=>a.lookupValue==y.lookupValue);
        if(prevValue){
           return {
            dataListId: prevValue.dataListId,
            dataListValueId: y.id,
            id: null,
            isActive: y.isActive,
            lookupValue: y.lookupValue,
            stageId: y.stageId,
            statusDisplayOrder: y.statusDisplayOrder
          };
         }
         else{
          return {
            dataListId: this.selectedEntityField.id,
            dataListValueId: y.id,
            id: null,
            isActive: y.isActive,
            lookupValue: y.lookupValue,
            stageId: y.stageId,
            statusDisplayOrder: y.statusDisplayOrder
          };
         }
      });
    }
   
    if(this.selectValues.length === 0) {
      this.showAllDataListValues =  1;
    }
    addAttributeData.showAllDataListValues = this.showAllDataListValues;
   
    addAttributeData.linkedAttributeId=null;

    if(this.selectedEntityField.parentLookupValues != null){
      let parentDataListId;
        const selectedParentValues: Array<any> = this.editFieldForm.controls.selectParentFieldValues.value;
        if(selectedParentValues && selectedParentValues.length>0){
        parentDataListId=this.selectedEntityField.parentRelationList
        .find(x=>selectedParentValues.includes(x.parentLookupValueId)).parentAttribId;

        let parentFieldValues= [];
        selectedParentValues.forEach(x => {
          parentFieldValues.push({dataListValueId:x, dataListId:parentDataListId, isActive:1});
        });
      addAttributeData.selectParentvalues = parentFieldValues;
      addAttributeData.parentDataListId = parentDataListId;
      }

    }

    if(this.selectedEntityField.childAttributeList != null){
      if(this.editFieldForm.controls.selectDependentFields.value?.length>0){
        let dependentFieldList = this.editFieldForm.controls.selectDependentFields.value
        .map(x => {return {dataListId: this.selectedEntityField.id, attributeId: x, isActive: 1}});
        
        addAttributeData.selectDependentFields = dependentFieldList;
      }
      
    }
  }
  else{
    addAttributeData.parentDataListId=null;
  }

  // Call the method and assign the returned object to a variable for opp status/value/winprobablity
const result = this.processChildAttributesForOppSysField();
const attribdDataForDelete = result.attribdDataForDelete;
const deleteRelation = result.deleteRelation;
const isParentFieldMandatory=result.isParentFieldMandatory;
if(isParentFieldMandatory){
  this.disabled = false;
  return;
}
    this.SpinnerService.show('update-data');

    const saveAttributeData = {
      id: this.editCustomVal.id,
      attributeName: this.attributeName?.trim(),
      projectId: this.selectedProject,
      parentAttribId: addAttributeData.linkedAttributeId,
      parentFormId: addAttributeData.parentFormId,
      instructions: addAttributeData.instructions,
      mandatoryFlag: this.mandatoryContactFlag,
      moduleType: this.moduleType,
      fieldSpec: this.editCustomVal.fieldSpec,
      displayOrder: this.editCustomVal.displayOrder,
      hasUniqueValues: this.uniqueFlag,
      attributeType: this.attributeType,
      characterType: this.characterType.id,
      minCharacters: addAttributeData.minCharacters,
      maxCharacters: addAttributeData.maxCharacters,
      allowBrowsing: this.allowBrowsingFlag,
      maxImagesAllowed: addAttributeData.maxImagesAllowed,
      numericValidationType: this.numericValidationType.id,
      minDigits: addAttributeData.minDigits,
      maxDigits: addAttributeData.maxDigits,
      minNumber: addAttributeData.minNumber,
      maxNumber: addAttributeData.maxNumber,
      documentType: addAttributeData.documentType,
      referenceDocumentUrl: addAttributeData.referenceDocumentUrl,
      isOptionsAlphabetically: this.alphabeticallyFlag,
      calculationFormula: this.calculationFormula,
      lookupValues: this.lookupVal,
      dateAttributes: this.dateAttributes,
      parentDataListId: addAttributeData.parentDataListId,
      addDataListDependentFields: addAttributeData.addDataListDependentFields,
      dependentFilelds: addAttributeData.selectDependentFields,
      parentFieldValues: addAttributeData.selectParentvalues,
      showAllDataListValues: addAttributeData.showAllDataListValues,
      status: this.statusFlag,
      attributeIdentifierName: addAttributeData.attributeIdentifierName,
      otpValidation: this.otpValidationFlag,
      decimalFlag: this.decimalFlag ? 1 : 0,
      isAllowQuantityTransfer: addAttributeData.isAllowQuantityTransfer,
      productQuantityTransferDetail: addAttributeData.productQuantityTransferDetail,
      allowPreviousForm: addAttributeData.ispreviousFormSelected,
      previousFormActivity: addAttributeData.previousFormActivity,
      addValidation: addAttributeData.addValidationFieldFlag,
      regexExpression: addAttributeData.regexExpression,
      errorMessage: addAttributeData.errorMessage,
      showOptionPreselected: this.showOptionPreselectedFlag
    }

    this._field
      .editFields(saveAttributeData)
      .pipe(
        tap(CustomAttibute => {
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {

        })
      )
      .subscribe(
        data => {
          this.loading = false;
          this.activeModal.close('SUCCESS');
          this.editFieldForm.reset();
          if(deleteRelation==true){
            this._rs.deleteRelation(attribdDataForDelete).subscribe(
              res1=>{
              },error=>{
                this.snackBar.open('Unable to delete the relation. Please try again.', '', {
                  duration: 2000,
                  panelClass: ['red-snackbar']
                });
              }
            )
          }
          this.eventEmitterService.onModalCloseResult('SUCCESS');
          this.SpinnerService.hide('update-data');
        },
        error => {
          this.errorMes = error.error?.responseData;
          this.disabled = false;
          this.eventEmitterService.onModalCloseResult('ERROR');
          this.SpinnerService.hide('update-data');
        }
      );
  }
  public processChildAttributesForOppSysField(): { attribdDataForDelete: { attributeId: string }[], deleteRelation: boolean ,isParentFieldMandatory:boolean} {
    let attribdDataForDelete: { attributeId: string }[] = [];
    let deleteRelation = false;
    let isParentFieldMandatory = false;
    let field:string[]=[];
    if(this.editCustomVal.hasChildAttribute && this.editCustomVal.childAttributeList){
    for (const res of this.editCustomVal.childAttributeList) {
      if ((res.systemAttribute?.id > 6 && res.systemAttribute?.id <10) && (this.mandatoryContactFlag == 0)) {
        field.push(res.attributeName);
        isParentFieldMandatory = true;
      } else if ((res.systemAttribute?.id > 6 && res.systemAttribute?.id <10) && (this.statusFlag == 0)) {
        deleteRelation = true;
        attribdDataForDelete.push({
          attributeId: res.id
        });
      }
    }
  }
  if(isParentFieldMandatory==true){
    const initialNotice = "You can not make this field as optional as it has " + field.join(', ') + " dependent on it. Please remove the relationship and try again";
    this.dialog.open(AlertDialogComponent, {
      width: '600px',
      position: { top: '20px' },
      data: { title: 'Alert', body: initialNotice }
    });
  }

    // Return the variables as an object
    return { attribdDataForDelete, deleteRelation, isParentFieldMandatory: isParentFieldMandatory };
  }

  mapToValue(ids:[]) : string{
    return ids.map(x => this.documentTypes.find(type => type.id==x)?.name).join("/");
  }

  changeStd(data){
   this.standardTypeValue.id=data;
  }

  changeMulti(data) {
    this.companyTypesId = data.toString();
  }

  displaySingleOption() {
    if (null != this.editCustomVal.moduleType && null != this.editCustomVal.systemAttribute) {
      if ((this.editCustomVal.moduleType == '2' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.COMPANY_GEO_FIELD_ID) ||
        (this.editCustomVal.moduleType == '1' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.CONTACT_COMPANY_FIELD_ID) ||
        (this.editCustomVal.moduleType == '1' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.CONTACT_GEO_FIELD_ID) ||
        (this.editCustomVal.moduleType == '3' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_COMPANY_NAME_FIELD_ID) ||
        (this.editCustomVal.moduleType == '3' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_GEO_FIELD_ID) ||
        (this.editCustomVal.moduleType == '105' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.ACTIVITY_SELECT_ESTIMATE_FIELD_ID) ||
        (this.editCustomVal.moduleType == '2' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.COMPANY_OWNER_FIELD_ID) ||
        (this.editCustomVal.moduleType == '3' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_OWNER_FIELD_ID)||
        (this.editCustomVal.moduleType == '1' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.CONTACT_OWNER_FIELD_ID) ||
        (this.editCustomVal.systemAttribute.id == SystemAttributeEnum.USER_ROLE_FIELD_ID || this.editCustomVal.systemAttribute.id == SystemAttributeEnum.USER_REPORTS_TO_FIELD_ID)) {
        this.singleSelectOption = false;
      }
    }
  }

  displayMultipleOption() {

    if (null != this.editCustomVal.moduleType && null != this.editCustomVal.systemAttribute) {
      if ((this.editCustomVal.moduleType == '1' && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.CONTACT_COMPANY_FIELD_ID) ||
        (this.editCustomVal.moduleType == '3' && (this.editCustomVal.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_CONTACT_FIELD_ID)
        ||(this.editCustomVal.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_OWNER_FIELD_ID)) ||
        (this.editCustomVal.moduleType == '2' && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.COMPANY_OWNER_FIELD_ID)) {
        this.multiSelectOption = false;
      }
    }
  }

  changeEntity(data) {
    this.SpinnerService.show('edit-field');
    if(data == 200){
      this.selectForm=false;
      this.isEntityField=false;
      this.productFormFlag = true;
      this.getselectionTypeList();
      this.isDisplaySelection = true;
      this.editFieldForm.controls.selectionType.enable();
      this.editFieldForm.controls.selectionType.setValidators([Validators.required]);
      this.editFieldForm.controls.selectionType.updateValueAndValidity();
      if(this.anyTransactionPerformed){
        this.editFieldForm.controls.entityValue.disable();
        this.editFieldForm.controls.selectionType.disable();
      }
      else{
        this.editFieldForm.controls.selectionType.setValue(this.editCustomVal.parentFormId);
        this.editFieldForm.controls.selectionType.enable();
      }
      this.isProductFormSelected = true;
    }else{
      this.getCustomFields(data);
      this.selectForm=true;
      this.isEntityField=true;
      this.isDisplaySelection=false;
      this.productFormFlag = false;
      this.editFieldForm.controls.selectionType.disable();
      this.editFieldForm.controls.selectionType.clearValidators();
      this.editFieldForm.controls.selectionType.updateValueAndValidity();
      if(this.anyTransactionPerformed){
        this.editFieldForm.controls.entityValue.disable();
        this.editFieldForm.controls.selectionType.disable();
      }
      else{
        this.editFieldForm.controls.selectionType.enable();
      }
    }
    this.SpinnerService.hide('edit-field');
  }

  clearDataListRelatedFieldsAndControls(){
    this.lookupVal=[];
    this.showParentValueDD=false;
    this.showDependentFieldsDD(false);
    this.parentLookupValues=[];
    this.showEntityFieldLV=false;
    this.selctedEntityFieldLV=[];
    this.dependentFieldList=[];
    this.dependentcheckBox=false;
    this.showDependentFieldCB=false;
    this.editFieldForm.controls.selectValues.setValue("");
    this.editFieldForm.controls.selectDependentFields.setValue("");
    this.editFieldForm.controls.selectParentFieldValues.setValue("");
  }

  changeEntityField(data){
    this.selectedEntityField = this.entityfieldList.find(field=>field.id===data);
    this.getDataListLookupValuesForParent(this.editCustomVal.parentDataListId);
  }

  dataListSetup(){
    if(this.selectedEntityField != undefined && this.selectedEntityField.moduleType==6){
      if (this.selectedEntityField.childAttributeList == null && this.selectedEntityField.parentLookupValues != null) {
        this.showParentValueDD=true;
        this.parentLookupValues=this.selectedEntityField.parentLookupValues;
        } 
  
        this.selctedEntityFieldLV=this.selectedEntityField.lookupValues;
  
      if(this.selectedEntityField.childAttributeList != null && this.selectedEntityField.parentLookupValues!=null){
        this.showDependentFieldCB=true;
        this.dependentFieldList=this.selectedEntityField.childAttributeList.filter(x => x.status==1);
        this.showParentValueDD=true;
        this.parentLookupValues=this.selectedEntityField.parentLookupValues;
      }
  
      if(this.selectedEntityField.childAttributeList != null && this.selectedEntityField.parentLookupValues==null){
        this.showDependentFieldCB=true;
        this.dependentFieldList=this.selectedEntityField.childAttributeList.filter(x => x.status==1);
      }
      if(this.selectParentvalues){
        this.showEntityFieldLV=true;
        this.changeparentFieldvalues(this.selectParentvalues);
      }
    }
  }

  changeparentFieldvalues(value){
    if(value.length>0) {
      let relationIds= this.selectedEntityField.parentRelationList
    .filter(x=>(value as Array<any>).includes(x.parentLookupValueId))
    .map(y=>y.childLookupValueId);
    this.selctedEntityFieldLV=this.selectedEntityField.lookupValues.filter(x=>relationIds.includes(x.id));
    } else {
      this.selctedEntityFieldLV=this.selectedEntityField.lookupValues;
    }
    this.showEntityFieldLV=true;
  }

  previousFormcheckboxChanged(event) {
    this.isPreviousFormcheckboxSelected = event.checked;
    const previousFormControl = this.editFieldForm.get('previousForm');
    if (event.checked) {
      this.getPreviousSubmittedFormDetails();
      previousFormControl.setValidators([Validators.required]);
    }

    else {
      previousFormControl.reset();
      previousFormControl.clearValidators();
    }
    previousFormControl.updateValueAndValidity();
  }

  //  Selection Change for Product Quantity Transfer Section
  onCheckboxChange(event) {
    const customerTransferControl = this.editFieldForm.get('customerTransfer');
    if(event.checked){
      customerTransferControl.setValidators([Validators.required]);
    } 
    else {
      customerTransferControl.reset();
      customerTransferControl.clearValidators();
      const entityTransferControl = this.editFieldForm.get('entityTransfer');
      entityTransferControl.reset();
      const entityFieldTransferControl = this.editFieldForm.get('entityFieldTransfer');
      entityFieldTransferControl.reset();
      entityFieldTransferControl.clearValidators();
      entityFieldTransferControl.updateValueAndValidity();
      const entityFieldValueTransferControl = this.editFieldForm.get('entityFieldValueTransfer');
      entityFieldValueTransferControl.reset();
      const showValuesControl = this.editFieldForm.get('showValues');
      showValuesControl.reset();
    }
    customerTransferControl.updateValueAndValidity();
  }

  changeEntityForQuantityTransfer(moduleType: number) {
    this.valuesForQuantityTransfer = [];
    this.getEntityFields(moduleType, false);

    //For User entity only 'Show Values' option made mandatory
    const showValuesControl = this.editFieldForm.get('showValues');
    if(moduleType == 5) {
      showValuesControl.setValidators([Validators.required]);
    }
    else {
      showValuesControl.clearValidators();
    }
    const entityTransferControl = this.editFieldForm.get('entityTransfer');
    entityTransferControl.setValidators([Validators.required]);
    entityTransferControl.updateValueAndValidity();

    const entityFieldTransferControl = this.editFieldForm.get('entityFieldTransfer');
    entityFieldTransferControl.setValidators([Validators.required]);
    entityFieldTransferControl.updateValueAndValidity();
    entityFieldTransferControl.setValue(null);

    const entityFieldValueTransferControl = this.editFieldForm.get('entityFieldValueTransfer');
    entityFieldValueTransferControl.setValidators([Validators.required]);
    entityFieldValueTransferControl.updateValueAndValidity();
    entityFieldValueTransferControl.setValue(null);

    showValuesControl.updateValueAndValidity();
  }

  changeEntityFieldForQuantityTransfer(fieldData, isFirst: boolean) {
    const entityFieldTransferControl = this.editFieldForm.get('entityFieldTransfer');
    entityFieldTransferControl.setValidators([Validators.required]);
    entityFieldTransferControl.updateValueAndValidity();
    this.valuesForQuantityTransfer = [];
    if(fieldData.systemAttribute?.id == 34 || fieldData.systemAttribute?.id == 58) {
      //Owner, Reports To
      this.SpinnerService.show('edit-field');
      this._activityService.getUserDownHierarchy(this.selectedProject).subscribe(res => {
        res['responseData'].forEach(element => {
          this.valuesForQuantityTransfer.push({
            id: element.id,
            name: element.fullName
          })
        });
        this.SpinnerService.hide('edit-field');
      }, error => {
        this.SpinnerService.hide('edit-field');
      });
    }
    else if(fieldData.systemAttribute?.id == 57) {
      //User Role
      this.SpinnerService.show('edit-field');
      this._activityService.getActiveRoles(this.selectedProject).subscribe(res => {
        res['responseData'].forEach(element => {
          this.valuesForQuantityTransfer.push({
            id: element.roleId,
            name: element.roleName
          })
        });
        this.SpinnerService.hide('edit-field');
      }, error => {
        this.SpinnerService.hide('edit-field');
      });
    }
    else {
      fieldData.lookupValues.forEach(element => {
        this.valuesForQuantityTransfer.push({
          id: element.id,
          name: element.lookupValue
        })
      });
    }
    if(isFirst) {
      const multiSelectValue = (this.quantityTransferConfigData.productQuantityTransferDetail.value?.split(',') || []).map(value => +value);
      this.editFieldForm.controls['entityFieldValueTransfer'].setValue(multiSelectValue);
    }
  }

  changeValueForQuantityTransfer() {
    const entityFieldValueTransferControl = this.editFieldForm.get('entityFieldValueTransfer');
    entityFieldValueTransferControl.setValidators([Validators.required]);
    entityFieldValueTransferControl.updateValueAndValidity();
  }

  /** For  Select All in Multiselect */
  
  toggleAllSelection(matSelect: MatSelect, ngModalValue: boolean) {
    if (ngModalValue) {
      matSelect.options.forEach((item: MatOption) => {(item.value!==undefined && item.value!==null) ? item.select() : null});
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick(matSelect: MatSelect, ngModelName: string) {
   let newStatus = true;
   matSelect.options.forEach((item: MatOption) => {
     if (!item.selected) {
       newStatus = false;
     }
   });
   this[ngModelName] = newStatus;
 }

  showDependentFieldsDD(checked){
    this.showDependentFieldDD=checked;
    this.dependentcheckBox=checked;

  }

  changeSelectionType(data) {
    if(this.isPreviousFormcheckboxSelected === true){
      this.getPreviousSubmittedFormDetails();
    }
    this.isProductFormSelected = true;
  }
  change(data) {
    this.fieldTypeChanged=true;
    this.attributeType.id = data;
    if (this.editCustomVal.linkedAttributeId == null || this.editCustomVal.linkedAttributeId == 0) {

      if (this.attributeType.id == 3 ||this.attributeType.id==21) {
        this.displayDateValueDiv = true;
        this.getStandardLookUpList();
        this.editFieldForm.controls.dateValueType.setValidators([Validators.required]);
        this.editFieldForm.controls.dateValueType.updateValueAndValidity();
      } else {
        this.displayDateValueDiv = false;
        this.editFieldForm.controls.dateValueType.clearValidators();
        this.editFieldForm.controls.dateValueType.updateValueAndValidity();
        this.resetDateValue();
      }
      this.lookupVal = [];
      if (this.attributeType.id == 2 && this.multiSelectOption == true) {
        this.displayMultiSelectDiv = true;
        this.lookupVal = this.editCustomVal.lookupValues;
        this.addArray();

        this.editFieldForm.controls.attributeMultiSelectName.setValidators([Validators.required]);
        this.editFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
      } else {
        this.displayMultiSelectDiv = false;
        this.editFieldForm.controls.attributeMultiSelectName.clearValidators();
        this.editFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
      }


      if (this.attributeType.id == 1 && this.singleSelectOption == true) {
        if ((this.moduleType == '3' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_STATUS_FIELD_ID)) {
          this.displaySingleSelectDivTable = true;
          this.addArrayForDivTable();
        }
        else if ((this.editCustomVal.linkedAttributeId == null || (this.editCustomVal.linkedAttributeId == 0)) && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.ACTIVITY_SELECT_ESTIMATE_FIELD_ID) {
          this.displaySingleSelectDiv = false;
        }
        else {
          this.displaySingleSelectDiv = true;
          this.lookupVal = this.editCustomVal.lookupValues;
          this.addArray();
          if ((this.moduleType == '2' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.COMPANY_STATUS_FIELD_ID) ||
            (this.editCustomVal.moduleType == '3' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.OPPORTUNITY_GEO_FIELD_ID) ||
            (this.editCustomVal.moduleType == '1' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.CONTACT_STATUS_FIELD_ID) ||
            (this.editCustomVal.moduleType == '4' && this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.PRODUCT_STATUS) ||
            (this.editCustomVal.moduleType == '4' && this.editCustomVal.id == 94) || 
            (this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.USER_SET_PASSWORD_CHECKBOX_FIELD_ID) || 
	          (this.editCustomVal.systemAttribute != null && this.editCustomVal.systemAttribute.id == SystemAttributeEnum.USER_STATUS_FIELD_ID)) {
            this.disableattributeSingleName = true;
            this.editFieldForm.controls.attributeSingleName.disable();
 
            const control = <FormArray>this.editFieldForm.controls['OptionsItems'];
            var length = this.editFieldForm.controls.OptionsItems.value.length;

            for (var i = 0; i < length; i++) {
              control.at(+i).get('OptionVal').disable();
            }
          }
          this.editFieldForm.controls.attributeSingleName.updateValueAndValidity();
        }
      } else {
        this.displaySingleSelectDiv = false;
        this.editFieldForm.controls.attributeSingleName.clearValidators();
        this.editFieldForm.controls.attributeSingleName.updateValueAndValidity();
      }

      if (this.attributeType.id == 6 || this.attributeType.id == 7) {
        this.addValidationField = true;
        if (this.editCustomVal.addValidation) {
          this.editFieldForm.controls['enterRgx'].setValidators([Validators.required, this._field.regexValidator()]);
          this.editFieldForm.controls['rgxErrorMessage'].setValidators([Validators.required]);
          this.editFieldForm.controls['enterRgx'].setValue(this.editCustomVal.regexExpression);
          this.editFieldForm.controls['rgxErrorMessage'].setValue(this.editCustomVal.errorMessage);
          this.editFieldForm.controls['enterRgx'].updateValueAndValidity();
          this.editFieldForm.controls['rgxErrorMessage'].updateValueAndValidity();
        }
      }
      else {
        this.addValidationField = false;
      }

      if (this.attributeType.id == 6 || this.attributeType.id == 8) {
        this.isDisplayText = true;
        this.editFieldForm.controls['characterType'].setValue(this.editCustomVal.characterType);
        this.editFieldForm.controls['minCharacters'].setValue(this.editCustomVal.minCharacters);
        this.editFieldForm.controls['maxCharacters'].setValue(this.editCustomVal.maxCharacters);
      } else {
        this.isDisplayText = false;
      }



      if (this.attributeType.id == 4) {

        this.isDisplayImage = true;
        var flag2 = this.editCustomVal.allowBrowsing;
        if (flag2 != null && flag2 == 1) {
          this.browseVal = true;
        } else {
          this.browseVal = false;
        }
        this.editFieldForm.controls['maxImagesAllowed'].setValue(this.editCustomVal.maxImagesAllowed);
      } else {

        this.isDisplayImage = false;
      }

      if (this.attributeType.id == 7) {
        this.isDisplayNumeric = true;
        this.editFieldForm.controls['numericValidationType'].setValue(this.editCustomVal.numericValidationType);

        if (this.editCustomVal.numericValidationType == 2) {
          this.editFieldForm.controls['numericMinValue'].setValue(this.editCustomVal.minNumber);
          this.editFieldForm.controls['numericMaxValue'].setValue(this.editCustomVal.maxNumber);
        }

        if (this.editCustomVal.numericValidationType == 1) {
          this.editFieldForm.controls['minDigits'].setValue(this.editCustomVal.minDigits);
          this.editFieldForm.controls['maxDigits'].setValue(this.editCustomVal.maxDigits);
        }
      } else {
        this.isDisplayNumeric = false;
      }

      if (this.attributeType.id == 9) {
        this.isDisplayEmail = true;
      } else {
        this.isDisplayEmail = false;
      }

      if(this.attributeType.id==10){
        this.headerText = true;
      }else{
        this.headerText = false;
      }
  
      if(this.attributeType.id==11){
        this.normalText = true;
      }else{
        this.normalText = false;
      }
  
      if(this.attributeType.id==12){
        this.pageSeperator = true;
      }else{
        this.pageSeperator = false;
      }

      if(this.attributeType.id==15){
        this.isDocumentUpload=true;
        this.getDocumentTypes();
        this.editFieldForm.controls['documentType'].setValue(this.editCustomVal.documentType.split(','));
        this.editFieldForm.controls['maxImagesAllowed'].setValue(this.editCustomVal.maxImagesAllowed);
      }else{
        this.isDocumentUpload=false;
      }
  
      if(this.attributeType.id==16){
        this.isVideoUpload=true;
        this.editFieldForm.controls['maxImagesAllowed'].setValue(this.editCustomVal.maxImagesAllowed);
      }else{
        this.isVideoUpload=false;
      }
      
      if(this.attributeType.id==17){
        this.displayRefDoc=true;      
        this.editFieldForm.controls.refDocument.setValidators([Validators.required]);
        this.editFieldForm.controls.refDocument.updateValueAndValidity();
        setTimeout(() => {
          this.showRefDocuments();
        }, 10);
      } else {
        this.displayRefDoc=false;
      }
    } else {
      if (this.attributeType.id == 4) {

        this.isDisplayImage = true;
        var flag2 = this.editCustomVal.allowBrowsing;
        if (flag2 != null && flag2 == 1) {
          this.browseVal = true;
        } else {
          this.browseVal = false;
        }
        this.editFieldForm.controls['maxImagesAllowed'].setValue(this.editCustomVal.maxImagesAllowed);
      } else {

        if(this.attributeType.id ==9){
          this.isDisplayEmail=true;
        }else{
          this.isDisplayEmail=false;
        }
       
      }
    }
    if(this.attributeType.id ==13){
      if(this.editCustomVal.linkedAttributeModuleId==6){
         this.setupDataListRelatedFormControls();
      }
    }else{
    // this.isDisplayLinkedField=false;
    }

    if(this.attributeType.id ===18){
      this.isDisplayLinkedDataListField = this.editCustomVal.linkedAttributeModuleId !== 6;
      this.isFieldTypeDataList = this.isDisplayLinkedDataListField ? false : true;
      this.isDisplayLinkedField=true;
      this.editFieldForm.controls.fieldValue.setValue(2);
      this.editFieldForm.controls.entityValue.setValue(6);
      this.changeEntity(6);
      this.getCustomFields2(this.editCustomVal.linkedAttributeModuleId);
      this.setupDataListRelatedFormControls();
    }else{
      this.isFieldTypeDataList = false;
    }

    if(this.attributeType.id ==19){
      this.editFieldForm.controls.fieldValue.setValue(2);
      this.isDisplayLinkedField=true;
      if(this.allEntityTypeList!==undefined && this.allEntityTypeList!==null){
        this.entityTypeList=this.allEntityTypeList.filter(ent=>[1,2,3].includes(ent.id));
      }
    }else{
    }
  }

  setupDataListRelatedFormControls(){
    if(this.editCustomVal.selectedDependentFields?.length>0){
      let dependentFieldList=this.editCustomVal.selectedDependentFields.map(x=>x.dataListId);
      this.selectDependentFields = dependentFieldList;
    }
    if(this.editCustomVal.selectedParentFiledValues!= null && this.editCustomVal.selectedParentFiledValues.length!= 0){
      let selectedParentField = (this.editCustomVal.selectedParentFiledValues as Array<any>).map(x => x.dataListValueId);
      this.selectParentvalues = selectedParentField;
    }

    if((this.editCustomVal.showAllDataListValues === 0 || this.editCustomVal.showAllDataListValues === null) && this.editCustomVal.lookupValues?.length>0){
      let selectedValues=this.editCustomVal.lookupValues.map(x=>x.dataListValueId);
      this.selectValues = selectedValues;
      this.showEntityFieldLV = true;
    }

    var flag = this.editCustomVal.addDataListDependentFields;
    if (flag == 1) {
      this.flag_dependentcheckBox = true;
      this.showDependentFieldsDD(true);
    } else {
      this.flag_dependentcheckBox = false;
      this.showDependentFieldsDD(false);
    }
    this.showEntityFieldLV = true;
  }

  addClickEventListeners(){
    let children = document.getElementsByClassName("closeImageButton");
		for (let i = 0; i < children.length; i++) {
		  children[i].addEventListener("click", (event: Event) => {
		  this.removeImage(event);
		  });
		}

    children = document.getElementsByClassName("uploaded-img-thumbnail");
		for (let i = 0; i < children.length; i++) {
		  children[i].addEventListener("click", (event: Event) => {
		    let id = event.target['dataset'].imageindex;
        let file = this.uploadedFileIdObj.find(el => el.id == id);
        let index = this.uploadedFiles.findIndex(el => {
        if(typeof el == 'string'){
          return el == file.name;
        }
        return el.name == file.name;
        });
        let url = this.fileControlValue[index];
        window.open(environment.imageUrl + url);
      });
		}
  }

  showRefDocuments(){
    this.uploadedFiles = this.editCustomVal.referenceDocumentUrl?.map(el => el.replace(environment.imageUrl, ''));
    var imageDiv=document.getElementById('selectedImages');
    let imgCount = this.uploadedImageCount;

    if (this.uploadedFiles && this.uploadedFiles[0]) {
      let filesAmount = this.uploadedFiles.length;
      for (let i = 0; i < filesAmount; i++) {
          let media = this.getDocumentIcon(this.uploadedFiles[i]);
          var data = '<div id="removableDiv'+imgCount+'" class="artist-collection-photo"> ' 
          			+ '<div class="remove-img-btn"><button id="myImgCont'+imgCount+'"  data-imageindex='+imgCount+' class="closeImageButton close removeImgButton" type="button"><img data-imageindex='+imgCount+' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
          			+ '<img width="90" height="100" src='+media+' alt="media" data-imageindex='+imgCount+' class="uploaded-img-thumbnail"></div> 	';
          
          imageDiv.innerHTML += data;

          this.addClickEventListeners();

          this.uploadedFileIdObj.push({
            id: imgCount,
            name: this.uploadedFiles[i]
          });
          imgCount++;
          this.uploadedImageCount++;
          this.fileControlValue.push(this.uploadedFiles[i]);
          this.editFieldForm.controls.refDocument.setValue(this.fileControlValue);
    }
  }
}

getSlicedErrorFileName(name: string){
  if(name.length <=20){
    return name;
  }
  return ". . . ." + name.slice(-20);
}

checkUploadQueue(event){
  this.fileUploadQueue--;
  if(this.fileUploadQueue==0) {
    event.target.value='';
    this.SpinnerService.hide("uploadRefDoc");
    this.disabled = false;
  }
}

  allowRgxValidationCB(event) {
    this.addValidationFieldFlag = event.checked;
    const rgxControl = this.editFieldForm.controls['enterRgx'];
    const rgxErrorControl = this.editFieldForm.controls['rgxErrorMessage'];

    if (event.checked) {
      this.enableRegexField = true;
      rgxControl.setValidators([Validators.required, this._field.regexValidator()]);
      rgxErrorControl.setValidators([Validators.required]);
    } else {
      this.enableRegexField = false;
      rgxControl.clearValidators();
      rgxControl.setValue(null);
      rgxErrorControl.clearValidators();
      rgxErrorControl.setValue(null);
    }

    rgxControl.updateValueAndValidity();
    rgxErrorControl.updateValueAndValidity();
  }

clickFile(){
  let element:HTMLElement =document.getElementById("fileInput") as HTMLElement;
  element.click();
}

processFile(event){
  this.editFieldForm.controls.refDocument.markAsPristine();
  this.editFieldForm.controls.refDocument.markAsUntouched();
  this.disabled = true;
  this.wrongFormat = false;
  this.uploadError = false;

  var imageFilesFromEvent = event.target.files;
  var imageDiv=document.getElementById('selectedImages');
    let imgCount = this.uploadedImageCount;

    if (imageFilesFromEvent && imageFilesFromEvent[0]) {
      this.SpinnerService.show("uploadRefDoc");
      let filesAmount = event.target.files.length;
      this.fileUploadQueue = filesAmount;
    for (let i = 0; i < filesAmount; i++) {
      if(imageFilesFromEvent[i].type.match('image')){
        let reader = new FileReader();   
        reader.onload = (event:any) => {
          
          var data = '<div id="removableDiv'+imgCount+'" class="artist-collection-photo"> ' 
          			+ '<div class="remove-img-btn"><button id="myImgCont'+imgCount+'"  data-imageindex='+imgCount+' class="closeImageButton close removeImgButton" type="button"><img data-imageindex='+imgCount+' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
          			+ '<img width="90" height="100" src="'+event.target.result+'" alt="media" data-imageindex='+imgCount+' class="uploaded-img-thumbnail"></div> 	';
          
          imageDiv.innerHTML += data;
          
	        this.addClickEventListeners();

          this.uploadedFileIdObj.push({
            id: imgCount,
            name: imageFilesFromEvent[i].name
          });
          imgCount++;
          this.uploadedImageCount++;
          this.checkUploadQueue(event);
        }
        this.refDocService.uploadDoc(+this.moduleType, imageFilesFromEvent[i], this.selectedProject).subscribe(res => {
          reader.readAsDataURL(imageFilesFromEvent[i]);
          this.uploadedFiles.push(imageFilesFromEvent[i]);
          this.fileControlValue.push(res['responseData'].name);
          this.editFieldForm.controls.refDocument.setValue(this.fileControlValue);
        },
        error =>{
          console.log("Error");
          this.uploadError = true;
          this.uploadErrorFileName = this.getSlicedErrorFileName(imageFilesFromEvent[i].name);
          this.checkUploadQueue(event);
        });
      }else if(imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      || imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
      || imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.presentationml.presentation')
      || imageFilesFromEvent[i].type.match('application/pdf')){
        let reader = new FileReader();
        reader.onload = (event:any) => {
          let docIcon = this.getDocumentIcon(imageFilesFromEvent[i].type);
          var data = '<div id="removableDiv'+imgCount+'" class="artist-collection-photo"> ' 
          			+ '<div class="remove-img-btn"><button id="myImgCont'+imgCount+'"  data-imageindex='+imgCount+' class="closeImageButton close removeImgButton" type="button"><img data-imageindex='+imgCount+' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
          			+ '<img width="90" height="100" src='+docIcon+' alt="media" data-imageindex='+imgCount+' class="uploaded-img-thumbnail"></div> 	';
          
          imageDiv.innerHTML += data;
          
	        this.addClickEventListeners();

          this.uploadedFileIdObj.push({
            id: imgCount,
            name: imageFilesFromEvent[i].name
          });
          imgCount++;
          this.uploadedImageCount++;
          this.checkUploadQueue(event);
        }
        this.refDocService.uploadDoc(+this.moduleType, imageFilesFromEvent[i], this.selectedProject).subscribe(res => {
          reader.readAsDataURL(imageFilesFromEvent[i]);       
          this.uploadedFiles.push(imageFilesFromEvent[i]);
          this.fileControlValue.push(res['responseData'].name);
          this.editFieldForm.controls.refDocument.setValue(this.fileControlValue);
        },
        error =>{
          console.log("Error");
          this.uploadError = true;
          this.uploadErrorFileName = this.getSlicedErrorFileName(imageFilesFromEvent[i].name);
          this.checkUploadQueue(event);
        });
      }else{
        this.wrongFormat = true;
        this.checkUploadQueue(event);
      }
      }
      this.editFieldForm.controls['maxImagesAllowed'].setValue(this.editCustomVal.maxImagesAllowed);
    }else{
      this.isDisplayImage=false;
    }
}

removeImage(clickEvent){
  let id = clickEvent.target.dataset.imageindex;
    let removeFile = this.uploadedFileIdObj.find(el => el.id == id);
    if(!removeFile){
      return;
    }
    let removeIndex = this.uploadedFiles.findIndex(el => {
      if(typeof el == 'string'){
        return el == removeFile.name;
      }
      return el.name == removeFile.name;
    });
      if(removeIndex<0){
        return;
      }
    
    this.uploadedFiles.splice(removeIndex, 1);
    this.fileControlValue.splice(removeIndex, 1);
    this.editFieldForm.controls.refDocument.setValue(this.fileControlValue);
    // remove images from ui
    var imageDiv=document.getElementById('selectedImages');
    imageDiv.innerHTML = "";
    this.uploadedFileIdObj = [];
    this.uploadedImageCount = 0;

    var imageFilesFromEvent = this.uploadedFiles;
    let imgCount = this.uploadedImageCount;

    if (imageFilesFromEvent && imageFilesFromEvent[0]) {
      let filesAmount = this.uploadedFiles.length;
    for (let i = 0; i < filesAmount; i++) {
      if(typeof imageFilesFromEvent[i] == 'string'){
        let media = this.getDocumentIcon(imageFilesFromEvent[i]);
          var data = '<div id="removableDiv'+imgCount+'" class="artist-collection-photo"> ' 
          			+ '<div class="remove-img-btn"><button id="myImgCont'+imgCount+'"  data-imageindex='+imgCount+' class="closeImageButton close removeImgButton" type="button"><img data-imageindex='+imgCount+' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
          			+ '<img width="90" height="100" src='+media+' alt="media" data-imageindex='+imgCount+' class="uploaded-img-thumbnail"></div> 	';
          
          imageDiv.innerHTML += data;

          this.addClickEventListeners();

          this.uploadedFileIdObj.push({
            id: imgCount,
            name: imageFilesFromEvent[i]
          });
          imgCount++;
          this.uploadedImageCount++;
      }else if(typeof imageFilesFromEvent[i] == 'object'){
        if(imageFilesFromEvent[i].type.match('image')){
          let reader = new FileReader();   
          reader.onload = (event:any) => {
            
            var data = '<div id="removableDiv'+imgCount+'" class="artist-collection-photo"> ' 
                  + '<div class="remove-img-btn"><button id="myImgCont'+imgCount+'"  data-imageindex='+imgCount+' class="closeImageButton close removeImgButton" type="button"><img data-imageindex='+imgCount+' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
                  + '<img width="90" height="100" src="'+event.target.result+'" alt="media" data-imageindex='+imgCount+' class="uploaded-img-thumbnail"></div> 	';
            
            imageDiv.innerHTML += data;
            
            this.addClickEventListeners();

            this.uploadedFileIdObj.push({
              id: imgCount,
              name: imageFilesFromEvent[i].name
            });
            imgCount++;
          }
          reader.readAsDataURL(imageFilesFromEvent[i]);
          this.uploadedImageCount++;
        }else if(imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        || imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        || imageFilesFromEvent[i].type.match('application/vnd.openxmlformats-officedocument.presentationml.presentation')
        || imageFilesFromEvent[i].type.match('application/pdf')){
          let reader = new FileReader();
          reader.onload = (event:any) => {
            let docIcon = this.getDocumentIcon(imageFilesFromEvent[i].type);
            var data = '<div id="removableDiv'+imgCount+'" class="artist-collection-photo"> ' 
                  + '<div class="remove-img-btn"><button id="myImgCont'+imgCount+'"  data-imageindex='+imgCount+' class="closeImageButton close removeImgButton" type="button"><img data-imageindex='+imgCount+' class="close-svg" src="./assets/media/svg/icons/General/Close.svg"></button></div> '
                  + '<img width="90" height="100" src='+docIcon+' alt="media" data-imageindex='+imgCount+' class="uploaded-img-thumbnail"></div> 	';
            
            imageDiv.innerHTML += data;
            
            this.addClickEventListeners();

            this.uploadedFileIdObj.push({
              id: imgCount,
              name: imageFilesFromEvent[i].name
            });
            imgCount++;
            
          }
          reader.readAsDataURL(imageFilesFromEvent[i]);
          this.uploadedImageCount++;
        }
      }
    }
    }
}

  getDocumentIcon(file){
    let xtnsion = file.split('.').slice(-1)[0];
    if(xtnsion.match('xlsx') || file.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')){
      return "./assets/media/svg/icons/General/file-excel-solid.svg";
    }
    if(xtnsion.match('docx') || file.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document')){
      return "./assets/media/svg/icons/General/file-alt-solid.svg";
    }
    if(xtnsion.match('pptx') || file.match('application/vnd.openxmlformats-officedocument.presentationml.presentation')){
      return "./assets/media/svg/icons/General/file-powerpoint-solid.svg";
    }
    if(xtnsion.match('pdf') || file.match('application/pdf')){
      return "./assets/media/svg/icons/General/PDF-File.svg";
    }
    if(xtnsion.match('jpg') || xtnsion.match('jpeg') || xtnsion.match('png')){
      return environment.imageUrl + file;
    }
  }

  getDocumentTypes(){
    this.documentTypes = [{id: '1', name: 'PDF'}, {id: '2', name: 'Word'}, {id: '3', name: 'Excel'}, {id: '4', name: 'Power Point'}];
  }
  


  isDisplayStandard(): boolean {
    if (this.editFieldForm.controls.dateValueType.value == 'Standard') {
      this.editFieldForm.controls.standardTypeValue.setValidators([Validators.required]);
      this.editFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return true;
    } else {
      this.editFieldForm.controls.standardTypeValue.clearValidators();
      this.editFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return false;
    }
  }

  isDisplayCustom(): boolean {
    if (this.editFieldForm.controls.dateValueType.value == 'Custom') {
      return true;
    } else {
      return false;
    }
  }

  changeValidation(data) {
    this.numericValidationType.id = data;
  }

  changeCharacterType(data) {
    this.characterType.id = data;
  }

  isDisplayNumericValue(): boolean {
    if (this.editFieldForm.controls.numericValidationType.value == '2') {

      return true;
    } else {
      return false;
    }
  }

  isDisplayNumericDigit(): boolean {
    if (this.editFieldForm.controls.numericValidationType.value == '1') {


      return true;
    } else {
      return false;
    }
  }

  isDisplayexistings(): boolean {
    if (Number(this.moduleType) > 100) {
      if (this.editFieldForm.controls.fieldValue.value == '2') {
        this.attributeTypeVal = 'Link from other entities';

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showOptions(val) {
    this.allowBrowseVal = val;
  }

  showOptionsUnique(val) {
    this.uniqueVal = val;
  }

  showOptionsEdit(val) {
    this.mandatoryContact = val;
  }

  allowDecimalsCB(val) {
    this.decimalFlag = val;
    if(val) {
      this.otpValidationCheck = false;
    } else {
      this.otpValidationCheck = true;
      const minVal: String = String(this.editFieldForm.get('numericMinValue').value);
      this.editFieldForm.get('numericMinValue').setValue(this.extractWholeNumber(minVal));
      const maxVal: String = String(this.editFieldForm.get('numericMaxValue').value);
      this.editFieldForm.get('numericMaxValue').setValue(this.extractWholeNumber(maxVal));
    }
  }
  
  extractWholeNumber(val: String): number {
    if(val!=='' && val!==null) {
      val = val.replace(/\.\d*/, '');
      if(val!=='' && val!==null && val.match(/^\d*$/) !==null) {
        return Number(val);
      }
    }
    return null;
  }

  showOptionsOtpValid(val) {
    this.otpValidationCheck=val;
  }

  showOptionsAlphabetically(val) {
    this.isOptionsAlphabetically = val;
  }

  showOptionPreSelected(val) {
    this.showOptionSelected = val;
  }

  resetDateValue() {
    this.editFieldForm.controls['dateValueType'].reset();
  }

  Close() {
    this.editCustomVal.lookupValues = this.lookupValPrev;
    this.activeModal.close();
    this.editFieldForm.reset();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editFieldForm.controls[controlName];
    if (!control) {
      return false;
    }

    if (controlName == "attributeSingleName" || controlName == "attributeMultiSelectName") {
      if (this.lookupVal.length > 0)
        control.setErrors(null);
      else
        control.setErrors({ required: true });
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  isControlHasErrorItems(controlName: string, validationType: string): boolean {

    const control = this.editFieldForm.controls['OptionsItems'].get(controlName);
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    this.duplicate = false;

    if ((value || '').trim()) {
      for (let fr of this.lookupVal) {
        var name = fr.lookupValue;
        if (value.trim().toLowerCase() == name.toLowerCase()) {
          this.duplicate = true;
        }
      }
      if (this.duplicate == false) {
        this.lookupVal = this.lookupVal.concat({
          lookupValue: value.trim(),
          isActive: 1, stageId: null
        });
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(lookup): void {
    this.lookupVal = this.lookupVal.filter(item => item.lookupValue != lookup.lookupValue);

  }
  getControls() {
    return (this.editFieldForm.get('OptionsItems') as FormArray).controls;
  }

  OpenAlert(data) {
    if (data == 0 || data == 'Inactive') {
      if (this.editCustomVal.hasChildAttribute == true) {
        const initialNotice = `Making this field inactive will hide all the dependent fields from add form. Delete relationships of dependent fields to make them visible in add form.`;
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '600px',
          position: { top: '20px' },
          data: { title: 'Alert', body: initialNotice }
        });
      }
    }
  }

  OpenAlertValidation(message) {
    const initialNotice = message;
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      position: { top: '20px' },
      data: { title: 'Alert', body: initialNotice }
    });

  }

}
