import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { companiesModel } from '../../../company-management/models/companies.model';
import { RoleList } from '../../../users-management/Models/role-list.model';
import { contactsModel } from '../../../contact-management/models/contacts.model';
import { customAttributesModel } from '../../../company-management/models/customAttributes.model';
import { opportunitiesModel } from '../../../opportunities/models/opportunities.model';
import { CustomAttibute } from '../../../contact-setting/models/custom-attibute.model';
import { SystemAttributeEnum } from '../../constant/SystemAttributeEnum.constants';
import { CustomAttributeTypeEnum } from '../../constant/CustomAttributeTypeEnum';
import { AttributeType } from '../../../contact-setting/models/attribute-type.model';

@Injectable({
  providedIn: 'root'
})
export class AdvanceSearchService {

  constructor(private http: HttpClient) { }



  getCompanyIdName(projectId: number, moduleType: Number, customerView: number = 3) {
    return this.http.get<companiesModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompanyIdName?projectId=' + projectId + '&moduleType=' + moduleType + '&customerView=' + customerView);
  }

  getUserRole(projectId: number) {
    return this.http.get<RoleList>(environment.baseUrl + '/setting/api/roles?projectId=' + projectId + '&activeStatus=1');
  }

  getAllUsers(projectId: number) {
    return this.http.post(environment.baseUrl + '/login/api/users/getAll?projectId=' + projectId, null);
  }

  getAllContactDetailsForSelfAndTeam(projectId: number, moduleType: Number) {
    return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/contact/getContactLookup?projectId=' + projectId + '&moduleType=' + moduleType);

  }


  getContactAllFieldContactDetails(projectId: string) {
    return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/contact/getContact?projectId=' + projectId);
  }

  getAdvanceSearchOperators() {
    return this.http.get(
      environment.baseUrl + '/transactions/api/contact/fetchAdvancedSearchOperators');
  }

  getOwner(projectId: number, moduleId: number) {
    return this.http.get<customAttributesModel[]>(
      environment.baseUrl + '/login/api/users/fetchModuleOwnerOptions?projectId=' + projectId + '&moduleId=' + moduleId);
  }
  getFields(moduleType, projectId: number) {
    return this.http.get<companiesModel>(environment.baseUrl + '/setting/api/customType/getFieldsInSetting?projectId=' + projectId + '&moduleType=' + moduleType)
  }

  getContactFilter(projectId: number, contactView: number, data) {
    return this.http.post(
      environment.baseUrl + '/transactions/api/contact/getContactLookupSelfOrTeam?projectId=' + projectId + '&contactView=' + contactView, data);
  }

  getCompanyFilter(projectId: number, viewMode: number, pageNumber: number, pageSize: number, data) {
    return this.http.post(
      environment.baseUrl + '/transactions/api/company/v3/getCompanyLookupSelfOrTeam?projectId=' + projectId + '&customerView=' + viewMode + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize, data);
  }
  getOpportunitiesFilter(projectId: number, opportunityView: number, pageNumber: number, pageSize: number, data) {
    return this.http.post(
      environment.baseUrl + '/transactions/api/opportunity/getOpportunityLookupSelfOrTeam?projectId=' + projectId + '&opportunityView=' + opportunityView + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize, data);
  }
  getUserFilter(projectId: number, pageSize: number, pageNumber: number, isFirstAdvanceSearch: boolean, data) {
    return this.http.post(environment.baseUrl + '/login/api/users/getAll?projectId=' + projectId + '&pageSize=' + pageSize + '&pageNumber=' + pageNumber + '&isFirstAdvanceSearch=' + isFirstAdvanceSearch, data);
  }
  getProductFilter(projectId: number, data) {
    return this.http.post(environment.baseUrl + '/transactions/api/product/getProductLookup?projectId=' + projectId, data);
  }
  getOpportunitiesGridViewFilter(projectId: number, opportunityView: number, advanceSearchdata) {
    return this.http.post(
      environment.baseUrl + '/transactions/api/opportunity/getOpportunityGridViewSelfOrTeam?projectId=' + projectId + '&opportunityView=' + opportunityView, advanceSearchdata);
  }

  fetchStaticFields(): CustomAttibute[] {
    const staticFields: CustomAttibute[] = [];
    staticFields.push(this.createStaticField(SystemAttributeEnum.CREATED_ON, "Created On", CustomAttributeTypeEnum.CREATED_UPDATED_DATE));
    staticFields.push(this.createStaticField(SystemAttributeEnum.UPDATED_ON, "Updated On", CustomAttributeTypeEnum.CREATED_UPDATED_DATE));
    staticFields.push(this.createStaticField(SystemAttributeEnum.OPPORTUNITY_SERIAL_KEY_ID, "Serial Key", CustomAttributeTypeEnum.TEXT_SINGLE_LINE));
    return staticFields;
  }

  private createStaticField(id: number, name: string, type: CustomAttributeTypeEnum): CustomAttibute {
    const staticField = new CustomAttibute();
    staticField.id = id;
    staticField.attributeType = new AttributeType(type, null);
    staticField.attributeName = name;
    return staticField;
  }
}