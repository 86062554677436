// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isMockEnabled: true, // You have to switch this, when your real back-end is done

  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  
	loginUrl: 'https://endpoints-backend.api.assistive.site/retailitynew',
  	baseUrl:'',
	imageUrl: 'https://static.1channel.co/',
	name:"assistive-prod",
	foamicoBandhanAccessToken: 'EAAWtJ0zNZB6cBO2wHvTtmXLqZA8bj2Ygm40SraETkEa5BQ40uszRZC1d1EUVGscW2kQ5Y3FQODh8S1EoO5HoAvFZAnIeW3BcgonBZAjuu2oZCIjycO0M97kvtbuPMrHZA6Xhal0FDBnZA0pw6x2LTZBzzROQfeoI3BY5pjDsnZBQkG0CgZBrFYPZAnHdHidBxkCjaKj0UgZDZD',
	googleMapsApiKey: 'AIzaSyCBswPv_IOVF2ka75N385NMsShJqzXmiew'
	
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
