import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { opportunitiesModel } from '../models/opportunities.model';
import { Contact } from '../../contact-setting/models/contact.model';
import { opportunitiesStatusModel } from '../models/opportunity-status.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { contactsModel } from '../../contact-management/models/contacts.model';
import { companiesModel } from '../../company-management/models/companies.model';
import { UserLocalizationService } from '../../shared/services/UserLocalization.service';
import { OpportunityGridViewModel } from '../_subs/opportunities-grid-view/models/opportunity-grid-view.model';
import { customAttributesModel } from '../../company-management/models/customAttributes.model';
@Injectable({
  providedIn: 'root'
})
export class OpportunitiesService {

  public recheckValidation: Subject<void> = new Subject();
  public onHeaderSelect: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
  public addOppGridViewFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public advanceSearchArrayData$: Subject<[]> = new Subject();

  constructor(private http:HttpClient,private userLocalization:UserLocalizationService) { }
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  getAllOpportunityDetails(companyId=null){
    return this.http.get<opportunitiesModel[]>(
      environment.baseUrl +'/transactions/api/opportunity/getOpportunityLookup?projectId='+this.selectedProject)
  }
  getAllOpportunityDetails2(companyId,teamFlag:boolean=false,projectFlag:boolean=false){
    return this.http.get<opportunitiesModel[]>(
      environment.baseUrl +'/transactions/api/opportunity/getOpportunityLookup?projectId='+this.selectedProject+'&companyId='+companyId+"&teamFlag="+teamFlag+"&projectFlag="+projectFlag)
  }

  getAllContactDeatils(projectId: number){
    return this.http.post<Contact[]>(
      environment.baseUrl +'/transactions/api/contact/getAllContactDetails',{"projectId": projectId})
  }

  addEditOpportunity(addDataValues){
    addDataValues.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<opportunitiesModel>(
      environment.baseUrl +'/transactions/api/opportunity/addEditOpportunity',addDataValues)
  }

  getAllOpportunityType(projectId: number){
    return this.http.post<opportunitiesStatusModel[]>(
      environment.baseUrl +'/opportunity/getAllOpportunityType',{"projectId": projectId})
  }

  getOpportunity(opportunityId, projectId: number){
    return this.http.get<opportunitiesStatusModel[]>(
      environment.baseUrl +'/transactions/api/opportunity/getOpportunity?projectId='+projectId+'&opportunityId='+opportunityId)
  }

  getOpportunitiesFilter(projectId:number, opportunityView:number,pageNumber:number,pageSize:number,data=null){
    return this.http.post<opportunitiesModel[]>(
      environment.baseUrl + '/transactions/api/opportunity/getOpportunityLookupSelfOrTeam?projectId='+projectId+'&opportunityView='+opportunityView+'&pageNumber='+pageNumber+'&pageSize='+pageSize,data
    );
  }

  getAllFields(projectId:number){
    return this.http.get(environment.baseUrl + `/transactions/api/customType/getFields?projectId=${projectId}&moduleType=3`);
  }

getAllContactDetails(projectId: number){ 
  return this.http.get<contactsModel[]>(
   environment.baseUrl + '/transactions/api/contact/getContactLookup?projectId='+ projectId);
}

getCompany(projectId:number){
  return this.http.get<companiesModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompanyLookup?projectId='+projectId+'&projectFlag=true');
}

uploadOpportunities(addDataValues){
  return this.http.post(
    environment.baseUrl +'/transactions/api/opportunity/upload', addDataValues);
}
  
getOpportunitiesDetailsForGridView(opportunityView: number, advanceSearchArray: any) {
  return this.http.post<OpportunityGridViewModel[]>(
    environment.baseUrl + '/transactions/api/opportunity/getOpportunityGridViewSelfOrTeam?projectId='+this.selectedProject+'&opportunityView='+opportunityView, advanceSearchArray);
}

setAddOppGridViewFlag(addOppFlag: boolean) {
  this.addOppGridViewFlag.next(addOppFlag);
}

  getCompanyIdName(projectId: number, moduleType: Number, customerView: number = 3) {
    return this.http.get<companiesModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompanyIdName?projectId=' + projectId + '&moduleType=' + moduleType + '&customerView=' + customerView);
  }

  getUserContacts(projectId: number) {
    return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/contact/getCustomerContacts?projectId=' + projectId);

  }

  getCustomerContact(projectId: number, companyId: string | number) {
    return this.http.get<contactsModel[]>(
      environment.baseUrl + '/transactions/api/contact/getCustomerContacts?projectId=' + projectId + '&companyId=' + companyId);
  }

  getOwner(projectId: number, moduleId: number) {
    return this.http.get<customAttributesModel[]>(
      environment.baseUrl + '/login/api/users/fetchModuleOwnerOptions?projectId=' + projectId + '&moduleId=' + moduleId);
  }

  getLinkedDataListDetails(moduleType, projectId: number) {
    return this.http.get(environment.baseUrl + '/transactions/api/customType/getLinkedDataList?projectId=' + projectId + '&moduleType=' + moduleType)
  }

  getFields(moduleType, projectId: number) {
    return this.http.get<companiesModel>(environment.baseUrl + '/setting/api/customType/getFieldsInSetting?projectId=' + projectId + '&moduleType=' + moduleType)
  }


  getFieldRelation(attributeId, attributeLookupId) {
    return this.http.get<companiesModel>(environment.baseUrl + '/setting/api/customType/getChildAttributes?attributeId=' + attributeId + '&attributeLookupId=' + attributeLookupId)
  }

  add(addData) {
    addData.transTimeZone = this.userLocalization.getUserTimeZoneCode();
    return this.http.post(environment.baseUrl + '/transactions/api/opportunity/addEditOpportunity', addData);
  }

  mapOpportunityContact(contactId, opportunityId, projectId) {
    return this.http.get<customAttributesModel[]>(
      environment.baseUrl + '/reports/api/opportunity/dashboard/updateContactMapping?projectId=' + projectId + '&opportunityId=' + opportunityId + '&contactId=' + contactId);
  }

  getParticularOpportunity(id, moduleType, projectId: number, callMongoAPI = false) {
    return this.http.get<opportunitiesModel[]>(
      environment.baseUrl + '/transactions/api/opportunity/getOpportunity?projectId=' + projectId + '&opportunityId=' + id);
  }

    //Loopkup
    getAttribLookup(attributeId: number) {
      return this.http.get<any[]>(
        environment.baseUrl + '/setting/api/customType/getAttribLookupValues?attributeId=' + attributeId);
    }
  
    //CustomDateAttribute
    getCustomDateAttribute(attributeId: number) {
      return this.http.get<any[]>(
        environment.baseUrl + '/setting/api/customType/getCustomDateAttributes?attributeId=' + attributeId);
    }
  
    //CustomDateAttribute
    getDataListLookupValues(attributeId: number) {
      return this.http.get<any[]>(
        environment.baseUrl + '/setting/api/customType/getDataListLookupValues?attributeId=' + attributeId);
    }

}
