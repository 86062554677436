export enum WorkflowActions {
	EMAIL = 1,
	SMS = 2,
	APP_NOTIFICATION = 3,
	CREATE_ACTIVITY = 4,
	UPDATE_VALUE = 5,
	APPROVAL = 6,
	NEW_CONDITION = 7,
	EVENT_OR_TRIGGER = 8,
	DELAY_TIMER = 9,
	TRIGGER_WEBHOOK = 12
}

export enum SystemModules {
	CONTACT = 1,
	COMPANY_OR_CUSTOMER = 2,
	OPPORTUNITY = 3,
	DATE_TIME = 7,
	APPROVAL = 8,
	ACTIVITY = 100

}

export enum SendToOptionId {
	Owner = 1,
	Reporting_Manager = 2,
	Selected_User = 3,
	Custom = 4,
	Selected_Role = 5

}

export const EmojiList: Array<{ key: string, value: string }> = [
	// SMILEYS
	{ key: "&#128512;", value: "&#128512;" },
	{ key: "&#128513;", value: "&#128513;" },
	{ key: "&#128514;", value: "&#128514;" },
	{ key: "&#128515;", value: "&#128515;" },
	{ key: "&#128516;", value: "&#128516;" },
	{ key: "&#128517;", value: "&#128517;" },
	{ key: "&#128518;", value: "&#128518;" },
	{ key: "&#128519;", value: "&#128519;" },
	{ key: "&#128520;", value: "&#128520;" },
	{ key: "&#128521;", value: "&#128521;" },
	{ key: "&#128522;", value: "&#128522;" },
	{ key: "&#128523;", value: "&#128523;" },
	{ key: "&#128524;", value: "&#128524;" },
	{ key: "&#128525;", value: "&#128525;" },
	{ key: "&#128526;", value: "&#128526;" },
	{ key: "&#128527;", value: "&#128527;" },
	{ key: "&#128528;", value: "&#128528;" },
	{ key: "&#128529;", value: "&#128529;" },
	{ key: "&#128530;", value: "&#128530;" },
	{ key: "&#128531;", value: "&#128531;" },
	{ key: "&#128532;", value: "&#128532;" },
	{ key: "&#128533;", value: "&#128533;" },
	{ key: "&#128534;", value: "&#128534;" },
	{ key: "&#128535;", value: "&#128535;" },
	{ key: "&#128536;", value: "&#128536;" },
	{ key: "&#128537;", value: "&#128537;" },
	{ key: "&#128538;", value: "&#128538;" },
	{ key: "&#128539;", value: "&#128539;" },
	{ key: "&#128540;", value: "&#128540;" },
	{ key: "&#128541;", value: "&#128541;" },
	{ key: "&#128542;", value: "&#128542;" },
	{ key: "&#128543;", value: "&#128543;" },
	{ key: "&#128544;", value: "&#128544;" },
	{ key: "&#128545;", value: "&#128545;" },
	{ key: "&#128546;", value: "&#128546;" },
	{ key: "&#128547;", value: "&#128547;" },
	{ key: "&#128548;", value: "&#128548;" },
	{ key: "&#128549;", value: "&#128549;" },
	{ key: "&#128550;", value: "&#128550;" },
	{ key: "&#128551;", value: "&#128551;" },
	// HEARTS
	{ key: "&#128150;", value: "&#128150;" },
	{ key: "&#128151;", value: "&#128151;" },
	{ key: "&#128152;", value: "&#128152;" },
	{ key: "&#128153;", value: "&#128153;" },
	{ key: "&#128154;", value: "&#128154;" },
	{ key: "&#128155;", value: "&#128155;" },
	{ key: "&#128156;", value: "&#128156;" },
	{ key: "&#128157;", value: "&#128157;" },
	{ key: "&#128158;", value: "&#128158;" },
	{ key: "&#128159;", value: "&#128159;" }
]

export const Icons = {
	trash: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	width="32" height="32"
	viewBox="0 0 24 24"
	style=" fill:#000000;"><path d="M22,5H17V2a1,1,0,0,0-1-1H8A1,1,0,0,0,7,2V5H2A1,1,0,0,0,2,7H3.061L4,22.063A1,1,0,0,0,5,23H19a1,1,0,0,0,1-.937L20.939,7H22a1,1,0,0,0,0-2ZM9,3h6V5H9Zm9.061,18H5.939L5.064,7H18.936Z"></path><path d="M8,10a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V11A1,1,0,0,0,8,10Z"></path><path d="M12,10a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V11A1,1,0,0,0,12,10Z"></path><path d="M15,11v6a1,1,0,0,0,2,0V11a1,1,0,0,0-2,0Z"></path></svg>`,
	emoji: `<svg xmlns="http://www.w3.org/2000/svg" 
	viewBox="0 0 16 16" 
	id="emoji"><path fill="#212121" d="M8,2 C11.3137,2 14,4.68629 14,8 C14,11.3137 11.3137,14 8,14 C4.68629,14 2,11.3137 2,8 C2,4.68629 4.68629,2 8,2 Z M8,3 C5.23858,3 3,5.23858 3,8 C3,10.7614 5.23858,13 8,13 C10.7614,13 13,10.7614 13,8 C13,5.23858 10.7614,3 8,3 Z M9.86353,9.66654 C10.0477,9.46078 10.3638,9.44327 10.5696,9.62743 C10.7753,9.8116 10.7928,10.1277 10.6087,10.3335 C9.96851,11.0487 9.03663,11.4999949 8,11.4999949 C6.96337,11.4999949 6.03148,11.0487 5.39132,10.3335 C5.20716,10.1277 5.22467,9.8116 5.43043,9.62743 C5.6362,9.44327 5.95229,9.46078 6.13646,9.66654 C6.59494,10.1788 7.25963,10.4999949 8,10.4999949 C8.74037,10.4999949 9.40505,10.1788 9.86353,9.66654 Z M6.25,6.25 C6.66421,6.25 7,6.58579 7,7 C7,7.41421 6.66421,7.75 6.25,7.75 C5.83579,7.75 5.5,7.41421 5.5,7 C5.5,6.58579 5.83579,6.25 6.25,6.25 Z M9.75,6.25 C10.1642,6.25 10.5,6.58579 10.5,7 C10.5,7.41421 10.1642,7.75 9.75,7.75 C9.33579,7.75 9,7.41421 9,7 C9,6.58579 9.33579,6.25 9.75,6.25 Z"></path></svg>`,
	image: `<svg viewBox="0 0 18 18"> <rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect> <circle class="ql-fill" cx="6" cy="7" r="1"></circle> <polyline class="ql-even ql-fill" points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"></polyline> </svg>`,
	gif: `<svg width="20px" height="20px" viewBox="0 0 0.6 0.6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.075 0.175h0.15v0.05H0.075v0.15h0.1V0.325H0.125V0.275h0.1v0.15H0.025v-0.25h0.05zm0.35 0h0.15v0.05H0.425v0.05h0.1v0.05H0.425v0.1H0.375v-0.25h0.05zm-0.1 0H0.275v0.25h0.05v-0.25z" fill="#000000"/></svg>`
}
