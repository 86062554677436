<div class="example-preview">
    <div>
        <form [formGroup]="addActivityForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Add New Activity</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Activity Name
                            <span class="mandatory-marker">*</span></label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #inputActivity  maxlength="500" formControlName="activityName" ktAutoTrimSpaces>
                        
                            <mat-hint align="end">{{inputActivity.value?.length || 0}}/500</mat-hint>
                            <mat-error *ngIf="isControlHasError('activityName', 'required')">Name cannot be blank.</mat-error>
                             <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon> 
                        </mat-form-field>                    
                    </div>
                </div>

<!-- Associate activity -->
                <div class="form-group row mt-6">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-5"> Associate this Activity with </label>
                   </div>
                   <div class="col-md-8">

                        <mat-checkbox [checked]="true" [class.mat-checkbox-disabled]="false"  disabled>
                            Activities
                        </mat-checkbox>

                        &nbsp;&nbsp;&nbsp;&nbsp;

                        <mat-checkbox #checkbox1  formControlName="allowForCompany" [(ngModel)]="allowForCompany" (change)="enableOrDisableGeofence()" [checked]="false" >
                            {{allowCompanyName}}
                        </mat-checkbox>

                        &nbsp;&nbsp;&nbsp;&nbsp;
                      <span *ngIf="opportunityEnabled" >
                        <mat-checkbox #checkbox2 formControlName="allowForOpportunity" [(ngModel)]="allowForOpportunity"  (change)="openDialog()" [checked]="false" >
                            {{allowOpportunityName}}
                        </mat-checkbox>  
                      </span> 

                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <mat-checkbox #checkbox3 formControlName="allowForDialer" [(ngModel)]="allowForDialer" [checked]="false" >
                        {{allowDialerName}}
                    </mat-checkbox>  

                        <br>
                        <mat-hint class="form-hint" align="start"  style="color: rgba(0,0,0,.54)">Tick the options from where this activity can be performed</mat-hint> 
                   </div>  
                </div> 

                <!-- Live location capture -->
                <div class="form-group row mt-6">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-5">Enforce Live Location Capture while Adding from App</label>
                   </div>
                   <div class="col-md-8">
                        <mat-checkbox  formControlName="captureLiveLocation" [(ngModel)]="captureLiveLocation" checked>
                            Yes
                        </mat-checkbox>
                        <br>
                        <mat-hint class="form-hint" align="start"  style="color: rgba(0,0,0,.54)">Tick this if you want to enable real time location during addition</mat-hint> 
                   </div>  
                </div> 

                <div class="form-group row mt-6">
                    <div class="col-md-4">
                        <label col-form-label form-padding-top-5>Geofence</label>
                    </div>
                    <div class="col-md-8">
                        <mat-checkbox #checkbox3 formControlName="geofence" [(ngModel)]="geofence" (change)="addGeofenceField(geofence)">
                            Yes
                        </mat-checkbox> 
                        <br>
                        <mat-hint class="form-hint" align="start"  style="color: rgba(0,0,0,.54)">Select to activate geofence.</mat-hint> 
                    </div>    
                </div>

                <div *ngIf="geofence==true" class="form-group row mt-6">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Geofence Distance (in mtr)
                            <span class="mandatory-marker">*</span></label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #inputGeoDist  maxlength="5" formControlName="geofenceDistance" ktStrictNumberOnlyDirective [(ngModel)]="geofenceDistance" 
                            >
                            <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                            <mat-hint align="end">{{inputGeoDist.value?.length || 0}}/5</mat-hint>
                            <mat-error *ngIf="isControlHasError('geofenceDistance', 'required')">Geofence Distance cannot be blank.</mat-error>
                             <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">format_list_numbered</mat-icon> 
                        </mat-form-field>                    
                    </div>
                </div>
                
                <div styles="height: 10px;"></div>
                <div class="form-group row mt-6">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Key
                            <span class="mandatory-marker">*</span></label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input  maxlength="5" formControlName="serialKey" [(ngModel)]=serialKey ktAutoTrimSpaces>    
                            <mat-hint align="start" style="color: rgba(0,0,0,.54);">
                            </mat-hint>
                            <mat-hint align="end" style="color: rgba(0,0,0,.54)"> {{input.value?.length || 0}}/5</mat-hint>
                            <mat-error *ngIf="isControlHasError('serialKey', 'required')">Serial No. Key cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError('serialKey', 'maxlength')"><strong>Maximum 5 characters</strong></mat-error>
                            <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                            
                        </mat-form-field>                    
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>                
            </div>
        </form> 
    </div>
</div>  
<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name="addActivity">  
    <p style="color: white">Loading... </p>  
</ngx-spinner> 
