export enum SystemAttributeEnum {

//company Module
COMPANY_MODULE=2,
COMPANY_NAME_FIELD_ID=2,
COMPANY_GEO_FIELD_ID=16,
COMPANY_STATUS_FIELD_ID=17,
COMPANY_TYPE_FIELD_ID=18,
COMPANY_ADDRESS_FIELD_ID=24,
COMPANY_OWNER_FIELD_ID=34,
COMPANY_CONTACT_NUMBER = 72,
COMPANY_SERIAL_KEY_ID=-21, //for serial key

//contact Module
CONTACT_MODULE=1,
CONTACT_NAME_FIELD_ID=1,
CONTACT_TYPE_FIELD_ID=10,
CONTACT_COMPANY_FIELD_ID=11,
CONTACT_MOBILE_FIELD_ID=12,
CONTACT_EMAIL_FIELD_ID=13,
CONTACT_STATUS_FIELD_ID=14,
CONTACT_GEO_FIELD_ID=15,
CONTACT_DESIGNATION_FIELD_ID=20,
CONTACT_ADDRESS_FIELD_ID=23,
CONTACT_OWNER_FIELD_ID=35,

//opportunity module
OPPORTUNITY_MODULE=3,
OPPORTUNITY_NAME_FIELD_ID=3,
OPPORTUNITY_COMPANY_NAME_FIELD_ID=5,
OPPORTUNITY_CONTACT_FIELD_ID=6,
OPPORTUNITY_VALUE_FIELD_ID=7,
OPPORTUNITY_STATUS_FIELD_ID=8,
OPPORTUNITY_WIN_PROBABILITY_FIELD_ID=9,
OPPORTUNITY_CLOSURE_DATE_FIELD_ID=19,
OPPORTUNITY_GEO_FIELD_ID=21,
OPPORTUNITY_ADDRESS_FIELD_ID=22,
OPPORTUNITY_IMAGE_FIELD_ID=43,
OPPORTUNITY_OWNER_FIELD_ID=65,
OPPORTUNITY_SERIAL_KEY_ID=-31, //for serial key

//activity module
ACTIVITY_MODULE=101,
ACTIVITY_COMPANY_FIELD_ID=25,
ACTIVITY_OPPORTUNITY_FIELD_ID=26,
ACTIVITY_CONTACT_FIELD_ID=27,
ACTIVITY_ADD_PRODUCTS_FIELD_ID=28,
ACTIVITY_OPP_VALUE_FIELD_ID=29,
ACTIVITY_OPP_STATUS_FIELD_ID=30,
ACTIVITY_OPP_EXP_CLOSURE_DATE_FIELD_ID=31,
ACTIVITY_OPP_WIN_PROBABILITY_FIELD_ID=32,
ACTIVITY_DESCRIPTION_FIELD_ID=33,
ACTIVITY_OWNER_FIELD_ID=36,
ACTIVITY_SELECT_ESTIMATE_FIELD_ID=40,
ACTIVITY_IMAGE_FIELD_ID=41,
ACTIVITY_NAME=44,
ACTIVITY_PERFORM_DATETIME=45,
ACTIVITY_LOCATION=46,
ACTIVITY_PERFORMED_BY=47,
ACTIVITY_ADD_PRODUCTS_FORM=71,
ACTIVITY_OBJECTIVE=126,

//dialerTypeActivity
DIALER_CALL_TYPE_FIELD_ID=66,
DIALER_CALL_DATE_TIME_FIELD_ID=67,
DIALER_DURATION_FIELD_ID=68,
DIALER_COMMENTS_FIELD_ID=69,
DIALER_CALL_RECORDING_FIELD_ID=70,


//Produc Module
PRODUCT_MODULE=4,
PRODUCT_PRICE=37,
PRODUCT_NAME=38,
PRODUCT_STATUS=39,

//Product Form module
PRODUCT_FORM_MODULE=200,
PRODUCT_FORM_PRODUCT=48,
PRODUCT_FORM_QUANTITY=49,
PRODUCT_FORM_LIST_PRICE=50,
PRODUCT_FORM_TAX_PERCENTAGE=51,
PRODUCT_FORM_TOTAL=52,

//User Module
USER_MODULE=5,
USER_FIRST_NAME_FIELD_ID=53,
USER_LAST_NAME_FIELD_ID=54,
USER_WORK_EMAIL_FIELD_ID=55,
USER_MOBILE_NUMBER_FIELD_ID=56,
USER_ROLE_FIELD_ID=57,
USER_REPORTS_TO_FIELD_ID=58,
USER_SET_PASSWORD_CHECKBOX_FIELD_ID=59,
USER_PASSWORD_FIELD_ID=60,
USER_STATUS_FIELD_ID=61,

DATA_LIST_MODULE=6,

//Static Field IDs
CREATED_ON = -99,
UPDATED_ON = -98,
ACTIVITY_NAME_Field = -97,
ACTIVITY_TYPE = -96,
PERFORMED_BY = -95,
ACTIVITY_SERIAL_KEY_ID=-101,

}