import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomAttibute } from 'src/app/views/pages/contact-setting/models/custom-attibute.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AdvanceSearchService } from './services/advanced-search.service';
import { CustomAttributeTypeEnum } from '../constant/CustomAttributeTypeEnum';
import { FieldService } from '../services/field.service';
import { AttributeType } from '../../contact-setting/models/attribute-type.model';
import { SystemAttributeEnum } from '../constant/SystemAttributeEnum.constants';
import { SystemModules } from '../../workflows/_subs/constants/workflows.constant';
import { SubheaderService } from 'src/app/core/_base/layout';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'kt-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
  moduleType: any;
  advancedSearchForm: FormGroup;
  numericBetween: boolean = false;
  dateBetween: boolean = false;
  rowData = [];
  lastRowIndex: number = 0;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  operatorListMap: Map<number, { key: number, value: string }[]> = new Map();
  operatorList: { key: number, value: string }[];
  customerEntityFieldList: CustomAttibute[] = null;
  opportunityEntityFieldList: CustomAttibute[] = null;
  usersFieldList: CustomAttibute[] = null;
  productsFieldList: CustomAttibute[] = null;
  contactEntityFieldList: CustomAttibute[] = null;
  subscriptions: Subscription[] = [];
  currentAttributeTypeId: number = 6;
  allFieldData = [];
  filteredData = [];
  restrictedSystemIds = [15, 16, 21, 59, 60];
  // restrictedAttributeIds = [4, 5, 12, 13, 14, 15, 16, 17, 19, 20, 21, 22];
  private restrictedAttributeIds: number[] = [CustomAttributeTypeEnum.IMAGE,
  CustomAttributeTypeEnum.RADIO, CustomAttributeTypeEnum.HEADER_TEXT, CustomAttributeTypeEnum.NORMAL_TEXT,
  CustomAttributeTypeEnum.PAGE_SEPARATOR, CustomAttributeTypeEnum.CALCULATED_FIELD, CustomAttributeTypeEnum.DOCUMENT_UPLOAD,
  CustomAttributeTypeEnum.AUDIO_VIDEO, CustomAttributeTypeEnum.REFERENCE_DOCUMENTS, CustomAttributeTypeEnum.DATA_LIST,
  CustomAttributeTypeEnum.OTP_VALIDATION, CustomAttributeTypeEnum.QR_CODE_SCANNER, CustomAttributeTypeEnum.TIME_DURATION
  ];
  disabled: boolean = false;
  teamFlag: boolean;
  opportunityView: number;
  contactView: number;
  customerView: number;
  gridViewFlag: boolean = false;
  selectedFormArrayEntitiesAndDataList: Array<{
    numericBetween: boolean; entityId: number, attributeTypeId: number, operatorList: { key: number, value: string }[], fieldValues: { id: number; name: string; }[], dateBetween: boolean
  }> = [];
  public advanceSearchArrayData = [];
  public totalDataCount = 0;
  pageSize: number = 10000;
  pageNumber: number = 1;
  isFirstAdvanceSearch: boolean = true;
  private filterCount: number = 0;
  private isDropdownOpened: boolean = false;

  @ViewChildren('entityFieldDropdown') entityFieldDropdowns: QueryList<MatSelect>;

  constructor(private formBuilder: FormBuilder,
    private _as: AdvanceSearchService,
    private _field: FieldService,
    public activeModal: NgbActiveModal,
    private SpinnerService: NgxSpinnerService,
    private _subheaderService: SubheaderService,
    private snackBar: MatSnackBar) {
    this.advancedSearchForm = this.formBuilder.group({
      rows: this.formBuilder.array([])
    });
    this.selectedFormArrayEntitiesAndDataList = [];
  }

  ngOnInit(): void {
    this.fetchtOperatorsThenSetEntityFieldLists();
  }

  ngAfterViewChecked(): void {
    if (!this.isDropdownOpened && this.entityFieldDropdowns?.first && this.advanceSearchArrayData.length == 0) {
      this.isDropdownOpened = true;
      setTimeout(() => this.openDropdown(), 500);
    }
  }

  private openDropdown(): void {
    const dropdown = this.entityFieldDropdowns?.first;
    if (dropdown) {
      dropdown.focus();
      dropdown.open();
    }
  }

  addNewRow() {
    this.selectedFormArrayEntitiesAndDataList.push({ entityId: null, attributeTypeId: CustomAttributeTypeEnum.TEXT_SINGLE_LINE, operatorList: [], fieldValues: [], numericBetween: false, dateBetween: false });
    const newRow = this.formBuilder.group({
      entityfield: ['', Validators.required],
      operator: ['', Validators.required],
      value: ['', Validators.required],
      fromvalue: ['', Validators.required],
      tovalue: ['', Validators.required]
    });
    this.rowsArray.push(newRow);
    this.lastRowIndex = this.rowsArray.controls.length - 1;
  }

  get rowsArray() {
    return (this.advancedSearchForm.get('rows') as FormArray);
  }

  isControlHasError(controlName: string, validationType: string, index: number): boolean {
    const control = this.rowsArray.at(index).get(controlName);
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }


  closeModal() {
    this.activeModal.close();
  }

  changeEntityField(attribute: CustomAttibute, rowIndex: number, isEdit: boolean = false) {
    if (isEdit) {
      this.setFormControlValuesBasedOnEditData(rowIndex, attribute);
    } else {
      this.rowsArray.at(rowIndex).get('operator').reset();
      this.rowsArray.at(rowIndex).get('value').reset();
      this.rowsArray.at(rowIndex).get('fromvalue').reset();
      this.rowsArray.at(rowIndex).get('tovalue').reset();
    }
    this.selectedFormArrayEntitiesAndDataList[rowIndex].attributeTypeId = attribute.attributeType.id;
    this.selectedFormArrayEntitiesAndDataList[rowIndex].operatorList = this.operatorListMap.get(attribute.attributeType.id);
    if (attribute.systemAttribute?.id == 11 || attribute.systemAttribute?.id == 5) {
      this.SpinnerService.show('fetchFields');
      this._as.getCompanyIdName(this.selectedProject, this.moduleType, 3).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = data['responseData'];
          this.SpinnerService.hide('fetchFields');
        }, error => {
          console.error('Error Occurred while fetching company data.', error);
          this.showErrorSnackBarAndClose(false);
        });
    }
    else if (attribute.systemAttribute?.id == 6) {
      this.SpinnerService.show('fetchFields');
      this._as.getAllContactDetailsForSelfAndTeam(this.selectedProject, this.moduleType).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getContactDetails(data['responseData']);
          this.SpinnerService.hide('fetchFields');
        }, error => {
          console.error('Error Occurred while fetching contact data.', error);
          this.showErrorSnackBarAndClose(false);
        });
    }
    else if (attribute.systemAttribute?.id == 34 || attribute.systemAttribute?.id == 35 || attribute.systemAttribute?.id == 65) {
      this._as.getOwner(this.selectedProject, Number(this.moduleType)).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getOwnerDetails(data['responseData']);
        })
    }
    else if (attribute.systemAttribute?.id == 57) {
      this._as.getUserRole(this.selectedProject).subscribe(
        result => {
          if (+result['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getUserRoleDetails(result['responseData']);
        })
    }
    else if (attribute.systemAttribute?.id == 58) {
      this._as.getAllUsers(this.selectedProject).subscribe(
        result => {
          if (result['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getAllUsersDetails(result['responseData'].fieldValues);
        }
      )
    }
    else {
      this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getFieldValues(attribute);
    }
  }

  private setFormControlValuesBasedOnEditData(rowIndex: number, attribute: CustomAttibute) {
    this.rowsArray.at(rowIndex).get('entityfield').setValue(attribute);
    this.rowsArray.at(rowIndex).get('operator').setValue(this.advanceSearchArrayData[rowIndex].operatorId);
    if (attribute.attributeType.id == CustomAttributeTypeEnum.SINGLE_SELECT_DROPDOWN || attribute.attributeType.id == CustomAttributeTypeEnum.MULTI_SELECT_DROPDOWN) {
      let array = this.advanceSearchArrayData[rowIndex].value.split(",").map(Number);
      this.rowsArray.at(rowIndex).get('value').setValue(array);
      this.rowsArray.at(rowIndex).get('fromvalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('fromvalue').updateValueAndValidity();
    }
    else if ((attribute.attributeType.id == CustomAttributeTypeEnum.NUMERIC && this.advanceSearchArrayData[rowIndex].operatorId == 5) ||
      ([CustomAttributeTypeEnum.DATE, CustomAttributeTypeEnum.CREATED_UPDATED_DATE].includes(attribute.attributeType.id) && [5, 6, 7, 8, 9, 10].includes(this.advanceSearchArrayData[rowIndex].operatorId))) {
      this.changeOperator(this.advanceSearchArrayData[rowIndex].operatorId, rowIndex);
      this.rowsArray.at(rowIndex).get('fromvalue').setValue(this.advanceSearchArrayData[rowIndex].fromValue);
      this.rowsArray.at(rowIndex).get('tovalue').setValue(this.advanceSearchArrayData[rowIndex].toValue);
      this.rowsArray.at(rowIndex).get('tovalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('fromvalue').updateValueAndValidity();
    } else {
      this.rowsArray.at(rowIndex).get('fromvalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('fromvalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('value').setValue(this.advanceSearchArrayData[rowIndex].value);
    }
  }

  getFieldValues(attribute: CustomAttibute) {
    let fieldValues: { id: number; name: string; }[] = [];
    if ((attribute.attributeType.id == 1 || attribute.attributeType.id == 2) && ((!(attribute.lookupValues) || (attribute.lookupValues?.length == 0)))) {
      this._field.getAttribLookup(attribute.id).subscribe(data => {

        data['responseData'].forEach(res => {
          fieldValues.push({ id: res.id, name: res.lookupValue })
        });
        attribute.lookupValues = data['responseData'];
      })
    } else if ((attribute.attributeType.id == 1 || attribute.attributeType.id == 2) && (attribute.lookupValues?.length > 0)) {
      attribute.lookupValues.forEach(res => {
        fieldValues.push({ id: res.id, name: res.lookupValue })
      })
    }
    return fieldValues;
  }

  getOwnerDetails(ownerList: any) {
    let fieldValues: { id: number; name: string; }[] = [];
    ownerList.forEach(res => {
      fieldValues.push({ id: res.id, name: res.fullName })
    })
    return fieldValues;
  }

  getContactDetails(contactList: any) {
    let fieldValues: { id: number; name: string; }[] = [];
    contactList.forEach(res => {
      fieldValues.push({ id: res.contactId, name: res.contactName })
    })
    return fieldValues;
  }

  getUserRoleDetails(usersRole: any) {
    let fieldValues: { id: number; name: string; }[] = [];
    usersRole.forEach(res => {
      fieldValues.push({ id: res.roleId, name: res.roleName })
    })
    return fieldValues;
  }

  getAllUsersDetails(allUsersList: any) {
    let fieldValues: { id: number; name: string; }[] = [];
    allUsersList.forEach(res => {
      fieldValues.push({ id: res[0], name: res[1] })
    })
    return fieldValues;
  }

  changeOperator(value, rowIndex: number) {
    if ([CustomAttributeTypeEnum.DATE, CustomAttributeTypeEnum.CREATED_UPDATED_DATE].includes(this.selectedFormArrayEntitiesAndDataList[rowIndex].attributeTypeId)) {
      this.selectedFormArrayEntitiesAndDataList[rowIndex].dateBetween = [5, 6, 7, 8, 9, 10].includes(value);
    } else if (this.selectedFormArrayEntitiesAndDataList[rowIndex].attributeTypeId == 7) {
      this.selectedFormArrayEntitiesAndDataList[rowIndex].numericBetween = value == 5;
    }

    if (value == 5) {
      this.updateControlsForDateRelatedOperators(rowIndex, null, null);
    }
    else if (value == 6) {
      //Last 7 days
      const today = new Date();
      let fromDay = new Date();
      fromDay.setDate(today.getDate() - 7);
      this.updateControlsForDateRelatedOperators(rowIndex, fromDay, today);
    }
    else if (value == 7) {
      //Last 15 days
      const today = new Date();
      let fromDay = new Date();
      fromDay.setDate(today.getDate() - 15);
      this.updateControlsForDateRelatedOperators(rowIndex, fromDay, today);
    }
    else if (value == 8) {
      //Last 30 days
      const today = new Date();
      let fromDay = new Date();
      fromDay.setDate(today.getDate() - 30);
      this.updateControlsForDateRelatedOperators(rowIndex, fromDay, today);
    }
    else if (value == 9) {
      // Last 3 Months
      const today = new Date();
      let fromDay = new Date();
      fromDay.setMonth(today.getMonth() - 3);
      this.updateControlsForDateRelatedOperators(rowIndex, fromDay, today);
    }
    else if (value == 10) {
      // Last 6 Months
      const today = new Date();
      let fromDay = new Date();
      fromDay.setMonth(today.getMonth() - 6);
      this.updateControlsForDateRelatedOperators(rowIndex, fromDay, today);
    }
    else {
      this.rowsArray.at(rowIndex).get('fromvalue').clearValidators();
      this.rowsArray.at(rowIndex).get('fromvalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('tovalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('value').setValidators([Validators.required]);
      this.rowsArray.at(rowIndex).get('value').updateValueAndValidity();
    }
  }

  fetchtOperatorsThenSetEntityFieldLists() {
    this.SpinnerService.show('fetchFields');
    this.subscriptions.push(
      this._as.getAdvanceSearchOperators().subscribe(res => {
        if (res['statusCode'] >= 400) {
          this.showErrorSnackBarAndClose(false);
          return;
        }
        (<Array<any>>res['responseData']).forEach(x => {
          this.operatorListMap.set(x.attributeType, x.operators);
        })
        this.SpinnerService.hide('fetchFields');
        this.setEntityFieldLists(this.moduleType);
      }, error => {
        console.error("Error occurrred while fetching Operator List.", error);
        this.showErrorSnackBarAndClose(false);
      })
    );
  }


  setEntityFieldLists(entityId: number) {
    let responseData: CustomAttibute[] = null;
    this.SpinnerService.show('fetchFields');
    this.subscriptions.push(this._as.getFields(this.moduleType, this.selectedProject).subscribe(res => {
      if (res['statusCode'] >= 400) {
        this.showErrorSnackBarAndClose(false);
        return;
      }
      responseData = res['responseData'].filter(x => {
        return x.status === 1 &&
          !(this.restrictedSystemIds.includes(x.systemAttribute?.id) ||
            this.restrictedAttributeIds.includes(x.attributeType.id))
      });
      if (entityId == 1) {
        this.contactEntityFieldList = responseData;
      }
      else if (entityId == 2) {
        this.customerEntityFieldList = responseData;
        const compSerialKeyAttrib: CustomAttibute = new CustomAttibute();
        compSerialKeyAttrib.id = SystemAttributeEnum.COMPANY_SERIAL_KEY_ID;
        compSerialKeyAttrib.attributeType = new AttributeType(CustomAttributeTypeEnum.TEXT_SINGLE_LINE, null);
        compSerialKeyAttrib.attributeName = "Serial Key"
        this.customerEntityFieldList.push(compSerialKeyAttrib);
        this.customerEntityFieldList.sort((a, b) => {
          if (a.id < 0 && b.id < 0) {
            return a.id - b.id; // Sort negative ids in ascending order
          }
          if (a.id < 0) {
            return -1; // a is negative, b is positive: a comes first
          }
          if (b.id < 0) {
            return 1; // b is negative, a is positive: b comes first
          }
          // Both a and b are positive, sort by displayOrder
          return a.displayOrder - b.displayOrder;
        });
      }
      else if (entityId === SystemModules.OPPORTUNITY) {
        this.opportunityEntityFieldList = responseData;
        this.opportunityEntityFieldList.push(...this._as.fetchStaticFields());
        this.opportunityEntityFieldList = this.sortFieldsByAttributeIdOrDisplayOrder(this.opportunityEntityFieldList, 'id', true);
      }
      else if (entityId == 4) {
        this.productsFieldList = responseData;
      }
      else if (entityId == 5) {
        this.usersFieldList = responseData;
      }
      if (this.advanceSearchArrayData.length > 0) {
        this.advanceSearchArrayData.forEach((r, index) => {
          this.addNewRow();
          this.selectedFormArrayEntitiesAndDataList[index].entityId = r.entityFieldId;
          this.selectedFormArrayEntitiesAndDataList[index].attributeTypeId = r.attributeTypeId;
          this.changeEntityField(responseData.find(a => a.id == r.entityFieldId), index, true);
        })
      } else {
        this.addNewRow();
      }
      this.SpinnerService.hide('fetchFields');
    }, error => {
      console.error("Error occurrred while fetching Entity Field List.");
      this.showErrorSnackBarAndClose(false);
    }));
  }

  private sortFieldsByAttributeIdOrDisplayOrder(fieldList: CustomAttibute[], attribute: string, negativeIdFirst: boolean = false): CustomAttibute[] {
    return fieldList.sort((a, b) => {
      if (negativeIdFirst && a[attribute] < 0 && b[attribute] < 0) {
        return a[attribute] - b[attribute]; // Sort negative ids in ascending order
      }
      if (negativeIdFirst && a[attribute] < 0) {
        return -1; // a is negative, b is positive: a comes first
      }
      if (negativeIdFirst && b[attribute] < 0) {
        return 1; // b is negative, a is positive: b comes first
      }
      // Sort by displayOrder if no negativeIdFirst condition is met
      return a?.displayOrder - b?.displayOrder;
    });
  }

  showErrorSnackBarAndClose(close: boolean = true) {
    this.SpinnerService.hide('fetchFields');
    this.snackBar.open('Unable to fetch data. Please try again.', '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
    if (close) {
      this.activeModal.close();
    }
  }

  keyPressNumbersWithDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  clearForm() {
    this.disabled = false;
    this.advancedSearchForm.reset();
  }

  removeRow(index: number) {
    this.rowsArray.removeAt(index);
    this.selectedFormArrayEntitiesAndDataList.splice(index, 1);
    if (this.lastRowIndex === this.rowsArray.controls.length) {
      this.lastRowIndex = this.rowsArray.controls.length - 1;
    }
  }

  private updateControlsForDateRelatedOperators(rowIndex: number, fromDate: Date, toDate: Date) {
    this.rowsArray.at(rowIndex).get('value').clearValidators();
    this.rowsArray.at(rowIndex).get('value').updateValueAndValidity();
    this.rowsArray.at(rowIndex).get('fromvalue').setValidators([Validators.required]);
    this.rowsArray.at(rowIndex).get('fromvalue').setValue(fromDate);
    this.rowsArray.at(rowIndex).get('fromvalue').updateValueAndValidity();
    this.rowsArray.at(rowIndex).get('tovalue').setValidators([Validators.required]);
    this.rowsArray.at(rowIndex).get('tovalue').setValue(toDate);
    this.rowsArray.at(rowIndex).get('tovalue').updateValueAndValidity();

    //Disable fromValue and toValue fields for PreFilled date options
    if (fromDate && toDate) {
      this.rowsArray.at(rowIndex).get('fromvalue').disable();
      this.rowsArray.at(rowIndex).get('tovalue').disable();
    }
  }

  onApplyFilters() {
    const formData = this.advancedSearchForm.getRawValue();
    const rowsArray = formData.rows as any[];
    this.rowsArray.controls.forEach((formGroup: FormGroup) => {
      Object.keys(formGroup.controls).forEach(controlName => {
        formGroup.get(controlName).markAsTouched();
      });
    });
    if (!this.advancedSearchForm.valid) {
      return;
    }
    let advanceSearchArray: any[] = [];
    for (const row of rowsArray) {
      if (row.entityfield.attributeType.id == 1 || row.entityfield.attributeType.id == 2 ||
        row.entityfield.attributeType.id == 18) {
        row.value = row.value ? row.value.join(',') : null;
      } else if ([CustomAttributeTypeEnum.DATE, CustomAttributeTypeEnum.CREATED_UPDATED_DATE].includes(row.entityfield.attributeType.id)) {
        if ([5, 6, 7, 8, 9, 10].includes(row.operator)) {
          row.fromvalue = row.fromvalue
            ? this.convertToLocalDateString(new Date(row.fromvalue))
            : null;
          row.tovalue = row.tovalue
            ? this.convertToLocalDateString(new Date(row.tovalue))
            : null;
        } else {
          row.value = row.value
            ? this.convertToLocalDateString(new Date(row.value))
            : null;
        }
      }

      const advanceSearhFilter = {
        "entityFieldId": row.entityfield.id,
        "attributeTypeId": row.entityfield.attributeType.id,
        "operatorId": row.operator,
        "value": row.value,
        "fromValue": row.fromvalue ? row.fromvalue : null,
        "toValue": row.tovalue ? row.tovalue : null,
      }
      advanceSearchArray.push(advanceSearhFilter);
    }
    this.applyFiltersToEntities(advanceSearchArray);
  }

  private convertToLocalDateString(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  private applyFiltersToEntities(advanceSearchArray: any[]): void {
    this.SpinnerService.show('fetchFields');
    if (this.moduleType == 1) {
      this._as.getContactFilter(this.selectedProject, this.contactView, advanceSearchArray).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose();
            return;
          }
          this.filteredData = data['responseData'];
          this.activeModal.close(this.filteredData);
          this.SpinnerService.hide('fetchFields');
        }, error => {
          this.showErrorSnackBarAndClose();
        });
    }

    else if (this.moduleType == 2) {
      const advanceSearchrequest = {
        "advanceSearch": advanceSearchArray,
        "isFirstAdvSearch": true
      }
      this._as.getCompanyFilter(this.selectedProject, this.customerView, +0, +10000, advanceSearchrequest).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose();
            return;
          }
          this.filteredData = data['responseData'].data;
          const response = {
            advanceSearchArray: advanceSearchArray,
            filteredData: this.filteredData,
            totalCount: data['responseData'].totalCountForAdvSearch
          }
          this.activeModal.close(response);
          this.SpinnerService.hide('fetchFields');
        }, error => {
          this.showErrorSnackBarAndClose();
        });
    }

    if (this.moduleType == SystemModules.OPPORTUNITY) {
      if (!this.gridViewFlag) {
        const advanceSearchRequest = {
          "advanceSearch": advanceSearchArray,
          "isFirstAdvSearch": true
        }
        this.subscriptions.push(this._as.getOpportunitiesFilter(this.selectedProject, this.opportunityView, +0, this.pageSize, advanceSearchRequest).subscribe(
          data => {
            if (data['statusCode'] >= 400) {
              this.showErrorSnackBarAndClose();
              return;
            }
            this.filteredData = data['responseData'].data;
            const response = {
              advanceSearchArray: advanceSearchArray,
              filteredData: this.filteredData,
              totalCount: data['responseData'].totalCountForAdvSearch
            }
            this.filterCount = advanceSearchArray.length;
            this._subheaderService.filterAppliedCount$.next(this.filterCount);
            this._subheaderService.showRemoveFilterIcon$.next(true);
            this.activeModal.close(response);
            this.SpinnerService.hide('fetchFields');
          }, error => {
            console.error('Error Occurred while applying advanced search.', error);
            this.showErrorSnackBarAndClose();
          }));
      } else {
        this.subscriptions.push(this._as.getOpportunitiesGridViewFilter(this.selectedProject, this.opportunityView, advanceSearchArray).subscribe(
          data => {
            if (data['statusCode'] >= 400) {
              this.showErrorSnackBarAndClose();
              return;
            }
            this.filteredData = data['responseData'];
            const response = {
              advanceSearchArray: advanceSearchArray,
              filteredData: this.filteredData,
            }
            this.filterCount = advanceSearchArray.length;
            this._subheaderService.filterAppliedCount$.next(this.filterCount);
            this._subheaderService.showRemoveFilterIcon$.next(true);
            this.activeModal.close(response);
            this.SpinnerService.hide('fetchFields');
          }, error => {
            console.error('Error Occurred while applying advanced search.', error);
            this.showErrorSnackBarAndClose();
          }));
      }
    }

    if (this.moduleType == 5) {
      this._as.getUserFilter(this.selectedProject, this.pageSize, this.pageNumber++, this.isFirstAdvanceSearch, advanceSearchArray).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose();
            return;
          }
          this.filteredData = data['responseData'];
          this.activeModal.close({
            filteredData: this.filteredData,
            advanceSearchArray: advanceSearchArray
          });
          this.SpinnerService.hide('fetchFields');
        }, error => {
          this.showErrorSnackBarAndClose();
        });
    }

    if (this.moduleType == 4) {
      this._as.getProductFilter(this.selectedProject, advanceSearchArray).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose();
            return;
          }
          this.filteredData = data['responseData'];
          this.activeModal.close(this.filteredData);
          this.SpinnerService.hide('fetchFields');
        }, error => {
          this.showErrorSnackBarAndClose();
        });
    }
    this.disabled = true;
  }
}

