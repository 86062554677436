import { Injectable } from "@angular/core";
import { Navigation } from "@angular/router";
import { MenuItemModel } from "src/app/core/_base/layout/models/menu-item.model";
import { MenuPermissionModel } from "src/app/core/_base/layout/models/menu-permission.model";

@Injectable({
    providedIn: 'root'
})
export class MenuPermissionService {

    prcessMenu(userMenu: Array<MenuItemModel>) {
        console.log(userMenu);
        let menuPermissionMap = new Map<number, Array<MenuPermissionModel>>();
        this.setMenuPermissionMap(userMenu, menuPermissionMap);
        let menuPermissionObj = {};
        menuPermissionMap.forEach((value, key) => {
            menuPermissionObj[key] = value;
        });
        localStorage.setItem('menuPermission', JSON.stringify(menuPermissionObj));
    }

    setMenuPermissionMap(menus: Array<MenuItemModel>, menuPermissionMap: Map<number, Array<MenuPermissionModel>>) {
        menus.forEach((x) => {
            if (x.menuPermission?.length > 0) {
                menuPermissionMap.set(x.menuId, x.menuPermission);
            }
            if (x.submenu && x.submenu?.length > 0) {
                this.setMenuPermissionMap(x.submenu, menuPermissionMap);
            }
        });
        return;
    }

    getMenuPermissionFromRouter(navigation: Navigation) {
        if (navigation && navigation.extras && navigation.extras.state) {
            return navigation.extras.state;
        }
        return null;
    }

    getAddEditAccessData(menuAccess: Array<MenuPermissionModel>, viewMode: number = 0) {
        if (viewMode === 0) {
            return {
                "isAddAllowed": true,
                "isEditAllowed": true,
                "isDeleteAllowed": true
            }
        }
        if (menuAccess) {
            const tabType = viewMode === 4 ? 3 : viewMode;
            const access = menuAccess.find(x => x.tabType === tabType);
            if (access) {
                return {
                    "isAddAllowed": access.addAccess === 1,
                    "isEditAllowed": access.editAccess === 1,
                    "isDeleteAllowed": access.deleteAccess === 1
                }
            }
        }
        return {
            "isAddAllowed": false,
            "isEditAllowed": false,
            "isDeleteAllowed": false
        }
    }

    getAddEditAccessDataByMenuId(menuId: number) {
        const allMenuPermission = JSON.parse(localStorage.getItem('menuPermission'));
        return allMenuPermission[menuId] ? allMenuPermission[menuId] : null;
    }

    getUploadAccessDataByMenuId(menuId: number): MenuPermissionModel {
        // Retrieve menu permissions from localStorage safely
        const allMenuPermission = JSON.parse(localStorage.getItem('menuPermission')) || {};
    
        // Get menuAccess for the given menuId
        const menuAccess = allMenuPermission[menuId] || [];
    
        // Try to find an existing permission entry for uploads (tabType = 4)
        const existingAccess = menuAccess.find((x: MenuPermissionModel) => x.tabType === 4);
        if (existingAccess) {
            return existingAccess;
        }
        return null;
    }
    
}

export enum MenuConstants {
    //crm
    CRM_CONTACTS = 608,
    CRM_CUSTOMERS = 610,
    CRM_OPPORTUNITIES = 611,
    CRM_ACTIVITIES = 647,
    USERS = 614,
}